@media only screen and (max-width: 820px) {
  .mobile {
    display: block !important;
  }
  .col-sm-12.heads.text.news {
    width: 95%;
}
  .text.news .col-sm-4 {
    width: calc(50% - 40px);
}
  .col-sm-9.singlenews p.date, .motor .col-sm-9.singlenews h2, .news .col-sm-12.heads img, .newsheight p {
    margin-left: 0 !important;
    padding-left: 0 !important;
    width: 100% !important;
}
.row.singleblog .col-sm-9.singlenews {
  width: 60%;
}
.row.singleblog .col-sm-3 {
  width: 40%;
}
.col-sm-12.heads.text.newsheight {
  padding: 0 12px;
}
  .sub.complainforms.whistleforms.pastPriceForm {
    width: 80%;
  }
  .row.whistleFormRow .col-sm-6.leftform.formstyle, .row.whistleFormRow .col-sm-6.formstyle.rightform{
    width: 100% !important;
    max-width: 100% !important;
  }
  .row.whistleFormRow .col-sm-6.formstyle.rightform{
    margin-top: 30px;
  }
  .sub.complainforms.whistleforms.fundPrices {
    width: 100% !important;
}
  .col-sm-12 .row.prodbox .col-sm-4 {
    opacity: 1;
    padding: 24px;
    width: 44%;
    margin: 10px 12px;
  }
  .row.mynewboxes .col-sm-6 {
    margin: 20px 10px;
    width: calc(50% - 20px);
    height: 340px !important;
} 
.container-fluid.shares.marbot .row{
  margin: 0 auto;
  justify-content: center;
}
.swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu .swiper-button-next, .swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu .swiper-button-prev {

  top: 97% !important;
}
  .row .swiper-slide h3,
  .col-sm-4.side .buy {
    font-size: 15px !important;
  }
  .col-sm-5.imageshare, .col-sm-7.audp.aboutContent {
    width: 80% !important;
  }
  .col-sm-12.heads.adds.firstpart .row.whoweare .col-sm-6, .row.whoweare.mission .col-sm-7, .row.whoweare.mission .col-sm-5 {
    width: 75% !important;
    max-width: 100% !important;
    flex: 0 0 100%;
  }
  .col-sm-12.heads.adds.firstpart .row.whoweare .col-sm-6 p, .col-sm-12.heads.adds.firstpart .row.whoweare .col-sm-6 h2{
    margin-left: 0 !important;
    padding-left: 5px;
    width: 100% !important;
    padding-top: 10px;
  }
  .container-fluid.shares.marbot.mufti img {
    margin-bottom: 0px !important;
  }
  .row.whoweare{
    flex-direction: column-reverse;
  }
  .row.whoweare h2 {
    padding-left: 5px;
    padding-bottom: 5%;
}
.row.whoweare.codeofconduct {
  flex-direction: column !important;
}
  .row.addsa.featureRow .col-sm-3{
    width: 50% !important;
  }
  .col-sm-4.suplimentsicon {
    width: 50%;
  }
  .row.mySuppliment.sumpleRider {
    padding: 0;
}
  .tabss .col-sm-12.heads.text .col-sm-4.suplimentsicon p{
    margin-left: 0 !important;
  }
  .desktop {
    display: none !important;
  }

  .swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu.swiper-backface-hidden .swiper-wrapper {
    height: 100%;
  }

  .illustrator .illustrationImage.col-sm-4 {
    margin-left: -70px;
    margin-top: 50px !important;
    width: 37%;
  }
  .ant-card-meta-title {
    margin: 15px 0 !important;
}
.ant-card-body h3 {
  text-align: center;
  padding-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}
.atThumbnail img {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 50px;
}
.container.governancemain.motor .marbot.CAPPITAAAL {
  margin-bottom: 0;
}
  .ant-card-meta-description {
    font-size: 15px;
    line-height: 23px;
  }
  .container.tabss.motor .row.tabscss.shhaariahAdvisor p {
    text-align: justify !important;
    width: 100% !important;
    padding-right: 0;
}

  .motor .sub.shariahcom p{
    width: 90% !important;
    text-align: justify !important;
  }
  .row.tabscss.shhaariahAdvisor img {
    max-height: 1000px !important;
    height: auto !important;

  }
  .row.whoweare.mission .col-sm-5.allvisions{
    width: 80% !important;
    padding-top: 30px !important;
  }
  .row.whoweare.mission .col-sm-5.allvisions .vision{
    padding-left: 0 !important;
  }
  .myfooter p, .container-fluid.shares.marbot p {
     width: 100% !important;
  }
  .container.homemenu .row {
    align-items: flex-start !important;
  }
  .container.homemenu .col-sm-4.side {
    margin-top: 20px;
  }
  .row.lifeCards .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%;
}
  
  .button-container.app-buttons {
    margin-top: 0% !important;
  }

  .menusildes.VIDEEEOS img {
    height: 450px;
    object-fit: cover;
  }
  .col-sm-4.illustrationCheckkkkk {
    width: 100% !important;
  }

  .row .col-sm-6.explore {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100% !important;
  }

  .col-sm-9.moDalOpleejnd {
    display: flex;
    justify-content: flex-end;
  }

  .row.preloader img {
    width: 25%;
  }

  .stepforms.steps {
    background-color: transparent;
    padding: 10% 5% !important;
    width: 100% !important;
  }

  .video-container {
    height: 100vh;
    /* height: 50%; */
    width: 100% !important;
    overflow-x: hidden;
  }

  body {
    overflow-x: hidden;
  }

  body {
    overflow-x: hidden;
  }

  nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
    justify-content: end;
    width: 100%;
    filter: invert(1);
  }

  .col-sm-6.buttonscta .call {
    padding: 12px 15px !important;
  }

  .container.banner:before {
    height: 0%;
  }

  .container.banner {
    height: 100vh;
  }

  .col-sm-4.side {
    width: 50%;
  }

  .col-sm-8.menus {
    width: 50% !important;
  }

  .googletranslatee {
    top: 9%;
    right: 2%;
  }

  .col-sm-6.illustResponse {
    min-width: 100% !important;
  }

  .motor .swiper-button-prev {
    right: 7% !important;
  }

  .illustrator {
    border-radius: 20px;
    background: #0FB583;
    padding: 10px 0px;
    color: #fff;
    margin: 20px 0px;
    height: auto;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: auto !important;
    right: 14%;
    top: 80% !important;
  }

  .donwloadapps h2 {
    font-size: 30px;
    color: #0e76bc !important;
    padding-bottom: 10px;
  }

  .container.donwloadapps {
    padding: 50px 0;
  }

  .container.donwloadapps .row {
    padding: 0 5%;
  }

  .donwloadapps .buy {
    padding: 15px 20px;
    font-size: 14px;
  }

  .col-sm-6.buttonscta {
    margin-bottom: 0% !important;
  }

  .col-sm-6.buttonscta {
    width: 30%;
    right: 0%;
  }

  .myfooter .accordion-button::after {
    filter: invert(1);
  }

  .myfooter .footerlogo {
    width: 15%;
  }

  .textcentr {
    text-align: center;
  }

  .sideSectionCanvas {
    display: block;
  }

  .myfooter p {
    width: 100%;
    font-size: 18px !important;
  }

  .sideSection {
    display: none;
  }
  .container-fluid.header svg {
    fill: #fff !important;
}
.container.copyright.mobile{
  display: block !important;
}
.container.copyright.desktop{
  display: none !important;
}
  .accordion {
    width: 100%;
    margin-top: 20px;
  }
  .container-fluid.header a.mobile {
    color: #fff !important;
}
  .myfooter button.accordion-button.collapsed {
    background: transparent !important;
    color: #fff !important;
    margin-bottom: 10px;
  }

  .myfooter button.accordion-button {
    background: transparent !important;
    color: #fff !important;
  }

  .myfooter .accordion-body {
    background: transparent !important;
    color: #fff !important;
  }

  .myfooter .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
  }

  .myfooter .accordion-item {
    border: unset;
    border-bottom: 1px solid #efefef;
  }

  .myfooter button.accordion-button {
    font-size: 22px;
  }

  .container.myfooter.showtab {
    padding: 12% 10% !important;
  }

  .container.copyright{
    padding: 25px 10%; 
  }

  .showtab {
    display: block !important;
  }

  .hidetab {
    display: none !important;
  }

  .esgSidebar {
    height: 60px;
    border-radius: 0;
  }

  .header img {
    width: 50px !important;
    margin: 20px 10px;
  }

  .accordion.accordion-flush {
    width: 100%;
    margin: auto;
  }

  .col-sm-12.heads.text.govdirectors .col-sm-4 {
    width: 50%;
    margin-bottom: 20px;
  }

  .govdirectors button.backbox {
    height: 490px !important;
    margin-top: 50px !important;
    overflow-y: scroll !important;
  }

  .govdirectors button {
    height: 490px;
    margin-bottom: 30px;

  }

  .shares h2 {
    text-align: center;
    color: #0e76bc;
    font-size: 26px;
  }

  .shares {
    background-color: #0fb583;
    padding-top: 50px;
  }

  .shares img {
    width: 100%;
    margin-bottom: 0;
  }

  .col-sm-12.shareholding {
    padding-top: 80px;
    padding-bottom: 0px;
    text-align: center;
  }

  .col-sm-12.shareholding a {
    width: 20%;
  }

  .dashboardSection {
    height: 100%;
  }

  .row.container.dashboardTabs {
    width: 100%;
  }

  .dashboardTabs .box {
    width: 100%;
    margin: 20px auto;
  }

  .reponsiveSidbar .offcanvas-body {
    overflow: hidden;
  }
  .row.addsa.myadongroup .col-sm-3 {
    width: 30%;
}
.tabss .col-sm-12.heads.text p.elemTxt {
  font-size: 20px !important;
  margin-bottom: 15px;
  margin-left: 0 !important;
}
.riders .suplimentsicon .supliment .row.mySuppliment.DIFFERENTIATORS img {
  width: 100px !important;
}
.container.tabss.motor.complaaainttt .sub.shariahcom.moreePadd {
  padding-top: 10px;
  padding-bottom: 60px;
}
.container.tabss.motor.complaaainttt .sub.shariahcom.moreePadd p{
  width: 100% !important;
}
.sub.complainforms .row{
  align-items: flex-start !important;
}
.col-sm-12.eligNumber div h1 {
  font-size: 36px !important;
}
.motor .col-sm-12.heads.text.mymaxheight.bot .swiper-button-prev {
  right: 8% !important;
}
/* .col-sm-3.suplimentsicon {
  width: 50%;
} */
.row.mySuppliment {
  padding: 36px 0 !important;
  margin: 0 !important;
}
.row.mynewboxes {
  width: 100%;
  margin-bottom: 30px;
}
.sub.productetxt.grrrrouupp .row, .sub.productetxt.grrrrouupp .row .col-sm-4.writeus {
  margin: 0 !important;
}
.sub.productetxt.grrrrouupp .row .col-sm-8.writeus{
padding-right: 5% !important;
}

.sub.productetxt.grrrrouupp .row .col-sm-8.writeus p{
padding-right: 0 !important;
}
.col-sm-12.eligNumber div {
  width: 100px !important;
  height: 100px !important;
}
  .f {
    position: initial;
    display: flex;
    justify-content: center;
  }
  .col-sm-12.heads.text.mymaxheight.po .sub.productetxt .col-sm-4 img {
    height: 400px;
    object-fit: cover;
    margin: 0 !important;
}
  .offcanvas-body {
    width: 107% !important;
  }
.container.tabss.motor.groupProduct{
  padding-top: 20px;
}
  .sideBarSec a p {
    text-align: center;
    font-size: 18px !important;
  }
  .col-sm-12.heads.text.mymaxheight.po .sub.productetxt .col-sm-8.writeus, .col-sm-12.heads.text.mymaxheight.po .sub.productetxt .col-sm-4.writeus {
    width: 100% !important;
}
  .row.contactLinks svg {
    height: 60px !important;
    margin-bottom: 10px;
    margin-left: 0 !important;
  }

  .col-sm-6.formstyle.contactFormstyle H3 {
    font-size: 36px;
  }

  .row.contactLinks .col-sm-4.pdfs h3 {
    font-size: 18px !important;
    width: 100% !important;
    text-align: center !important;
  }

  .container.mypdfss.contactLinks {
    width: 100%;
  }

  .row.contactLinks .col-sm-4.pdfs a {
    font-size: 12px;
    width: 100%;
    text-align: center !important;
  }

  .container.mypdfss.contactLinks .col-sm-4.pdfs {
    word-break: break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left !important;
  }

  p.noJOb {
    text-align: center;
    font-weight: 500;
    font-size: 20px !important;
  }

  .sub.complainforms.container .col-sm-6 {
    width: 100% !important;
    flex: 0 0 100%;
    max-width: 100% !important;
  }

  .col-sm-12.heads.text.mymaxheight.interntabs {
    justify-content: center;
    align-items: center;
  }

  .sub.shariahtabs.carerjobs {
    width: 80%;
  }

  .video-container.mobile img {
    width: 100%;
    height: 100vh;
  }

  .policyPage.container .accordion-button {
    font-size: 18px;
  }

  .patern li,
  .container-fluid.shares.marbot h2 {
    width: 100% !important;
  }
  .container-fluid.header {
    padding: 0;
  }
  .container.homemenu .col-sm-4, .container.homemenu .col-sm-8 {
    max-width: 50% ;
    flex: 0 0 50% ;
  }
  .col-sm-12.heads.text.mymaxheight.complainbox {
    padding: 0;
}
.container.mypdfss {
  width: 90%;
}
.col-sm-12.heads.text.mymaxheight.complainbox .col-sm-6.formstyle {
    padding: 20px 12px;
}
.tabss .col-sm-4.ctaimgae img {
  margin: 0 !important;
  height: 340px;
  object-fit: cover;
}
  .row.tabscss.shhaariahAdvisor .col-sm-4,
  .row.tabscss.shhaariahAdvisor .col-sm-8, .container.tabss.motor.bgwhite .col-sm-12.heads.text.mymaxheight.bot .tab-content .col-sm-6 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
    margin-bottom: 20px;
  }
  .sub.complainforms img {
    object-fit: contain;
    height: 100%;
}
  .col-sm-3.write {
    width: 50%;
    margin: auto;
  }
  .container.tabss.motor.bgwhite .col-sm-12.heads.text.mymaxheight.bot .tab-content .col-sm-6 p{
    width: 100% !important;
    font-size: 16px !important;
    line-height: 22px;
    font-weight: 500;
  }
  .govdirectors .row.governanceImages {
    width: 100%;
  }
  .container.lifeCardsCont{
    max-width: 100%;
  }
  .ant-card.ant-card-bordered.ant-card-hoverable {
    min-height: 870px;
  }
  .container-fluid.shares.marbot.CAPPITAAAL .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667% !important;
  }
  .row .govdirectors {
    padding: 60px 0 !important;
  }
  .motor .govdirectors button.backbox p{
    font-size: 13px !important;
  }
  .col-sm-12.shareholding.myntn {
    padding-top: 30px;
  }
  .col-sm-9.singlenews .col-sm-12.heads.text.newsheight.noHeightttt h2 {
    font-size: 18px !important;
}
}

@media only screen and (max-width: 520px) {
  .header img {
    width: 50px !important;
    margin: 20px 10px;
  }
  ul.articleLinksss{
    padding-left: 0 !important;
  }
  ul.articleLinksss {
    padding: 20px 0;
  }
  .singleblog {
    padding: 60px 0px;
}
  ul.articleLinksss li.liissttt{
    margin-left: 0 !important;
  }
  ul.articleLinksss li.liissttt a{
    word-break: break-all;

  }
  .col-sm-9.singlenews .col-sm-12.heads.text.newsheight.noHeightttt h2 {
    font-size: 16px !important;
  }
  .container-fluid.shares.marbot.CAPPITAAAL .col-sm-5 {
    flex: 0 0 auto;
    width: 100% !important;
    margin-bottom: 30px;
  }
  .row.singleblog .col-sm-9.singlenews, .row.singleblog .col-sm-3  {
    width: 100% !important;
  }
  .singlenews .col-sm-12.heads h2 {
    font-size: 20px !important;
}
.sidenews {
  margin: 0 15px !important;
}
.sidenews .row.ArticlesAndBlogs .col-sm-5 {
  width: 41.66666667%;
}
.sidenews .row.ArticlesAndBlogs .col-sm-7 {
  width: 58.33333333%;
}
  .col-sm-5.imageshare, .col-sm-7.audp.aboutContent {
    width: 100% !important;
}
  .motor .container-fluid.shares.marbot.CAPPITAAAL .col-sm-7.audp h2 {
    font-size: 22px !important;
  }
  .whistleforms .ant-col.ant-col-8, .row.lifeCards .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
}
  .motor .col-sm-12.heads.text.mymaxheight.bot .swiper-button-prev {
   right: 15% !important;
  }
  .motor .swiper-button-next {
    right: 3% !important;
}
.atThumbnail img {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 0px;
}
.container.governancemain.motor.nnewwBAnnner .col-sm-4.vidostak {
  flex: 0 0 auto;
  width: 100%;
  max-width: 100%;
}

.container.homemenu .col-sm-4, .container.homemenu .col-sm-8 {
  max-width: 100% ;
  flex: 0 0 100% ;
}
.atThumbnail{
  padding-bottom: 50px;
}
.icon.anticon svg {
  font-size: 40px !important;
  margin-left: 0 !important;
}
.wave {
  width: 50px;
  height: 50px;
}
.growth .ant-card.ant-card-bordered.ant-card-hoverable,.ant-card.ant-card-bordered.ant-card-hoverable {
  min-height: 100px !important;
}
.whistleforms h4 {
  font-size: 16px !important;
  padding-top: 20px;
}
.col-sm-12.heads.text.mymaxheight .swiper-wrapper {
  min-height: 480px;
}
.tabss .col-sm-12.heads.text .mycontents p {
  font-size: 16px !important;
  text-align: justify;
}
.sub.productetxt.uniqueprods .row{
  margin: 0 !important;
}
.col-sm-12.careerText h6,.careerText p {
  text-align: justify;
  padding: 0 6% 5px !important;
}
.row.neeewww{
  margin: 0 !important;
}
.col-sm-12.heads.text.mymaxheight .swiper.swiper-initialized.swiper-horizontal.mySwiper.slidesbyvcan .swiper-wrapper{
  min-height: 100px !important;
}
.sub.productetxt.uniqueprods .row.mySuppliment{
  padding: 10px 0 !important;
}
.col-sm-12.eligNumber div{
  margin-bottom: 0;
}
.tabss .col-sm-12.heads.text .row.mySuppliment h3{
  font-size: 17px !important;
}
  .col-sm-3.suplimentsicon {
    width: 50% !important;
    margin: 20px 0 0;
  }
  .col-sm-3.suplimentsicon .supliment{
    padding-bottom: 0 !important;
  }
  .tabss .col-sm-12.heads.text p.elemTxt {
    font-size: 18px !important;
    margin-bottom: 15px !important;
    margin-left: 0 !important;
    width: 100% !important;
}
.col-sm-4.suplimentsicon{
  width: 100% !important;
}
  .row.contactLinks svg {
    margin-bottom: 10px;
  }
  .row.contactLinks .col-sm-4.pdfs h3,.row.contactLinks .col-sm-4.pdfs a{
    text-align: center !important;
  }
  .naavBox.hassans {
    width: 70% !important;
    margin: auto !important;
    min-height: 60px !important;
  }
  .slidesbyvcan .swiper-button-prev {
    top: 50% !important;
    right: unset !important;
    left: 10px !important;
  }
  .col-sm-12.heads.text.mymaxheight.maps {
    padding-top: 0;
  }
  .col-sm-6.formstyle.contactFormstyle H3 {
    font-size: 30px;
  }
  .col-sm-12.heads.text.mymaxheight.maps img {
    height: 300px;
    object-fit: cover;
  }
  .slidesbyvcan .swiper-button-next {
    top: 50% !important;
    right: 10px !important;
  }
  .tabss .col-sm-12.heads .row.prodbox .col-sm-4 h3{
    font-size: 16px !important;
  }
  .tabss .col-sm-12.heads.text .row.prodbox .col-sm-4 p{
    font-size: 13px !important;
  }
  .row.prodbox.grooups {
    justify-content: space-evenly;
}
  .esgSidebar {
    height: 40px;
    border-radius: 5px;
  }
  .col-sm-12.heads.adds.firstpart .row.whoweare .col-sm-6 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100%;
  }
  .browchure button {
    width: calc(50% - 10px) !important;
    margin-bottom: 40px;
  }
  .sub.productetxt.uniqueprods.grrrroupppp{
    padding: 50px 0;
  }
  .row.prodbox.grooups, .row.prodbox.indiiviiaal{
    padding: 40px 0 20px !important;
  }
  .row.mySuppliment p {
    padding: 0 !important;
    margin: 0px !important;
  }
  .heads h2, .container.governancemain.motor .heads.govbanner h2, .container.governancemain.motor .heads.govbanner1 h2 {
    font-size: 36px !important;
  }
  
  .row.prodbox.grooups [data-aos=fade-up], .row.prodbox.indiiviiaal [data-aos=fade-up] {
    transform: translateZ(0) !important;
    /* transform: inherit !important; */
  }
  .row.whoweare button {
    width: 100%;
    margin-top: 30px;
  }
  .container-fluid.shares.marbot .col-sm-7.audp h3 {
    text-align: left;
  }
  .col-sm-12.heads.text.mymaxheight.interntabs h2 {
    text-align: center;
  }
  p.react-reveal.noJOb {
    text-align: center;
    padding: 50px 0 0 !important;
  }
  .naavBox.hassans p {
    font-size: 18px !important;
  }
  .row.recommendations .col-sm-5 {
    padding: 10px 0px;
  }
  .row.recommendations .col-sm-4 {
    padding: 10px 0px;
  }
  .swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu.swiper-backface-hidden .swiper-wrapper {
    min-height: 720px;
  }
  .col-sm-12.heads.adds.firstpart .row.whoweare .col-sm-6, .row.whoweare.mission .col-sm-7, .row.whoweare.mission .col-sm-5 {
    width: 100% !important;
  }
  .row.whoweare .col-sm-7.visionVideo img {
    width: 90%;
  }
  .col-sm-5.allvisions .vision{
    padding-left: 0;
  }
  .col-sm-7.visionVideo {
    text-align: center;
  }
  .col-3.uploadsbtns {
    width: 100%;
    margin-bottom: 15px;
  }
  .motor h2.shariahhead {
    font-size: 30px !important;
  }
  .swiper.swiper-initialized.swiper-horizontal.mySwiper.slidesbyvcan{
    width: 100% !important;
  }
  .uploadsbtns .ant-form-item button {
    align-items: center;
    display: flex;
  }
  .container.copyright.mobile {
    padding-bottom: 100px;
}
  .col-sm-8.stepforms.step.steps {
    min-width: 100%;
  }
  .col-sm-12.heads.text.mymaxheight .swiper.swiper-initialized.swiper-horizontal.swiper-ios.mySwiper.slidesbyvcan .swiper-wrapper{
    min-height: 100px !important;
  }
  .illustrator .illustrationImage.col-sm-4 {
    margin-left: -10px;
    margin-top: 20px !important;
    width: 100%;
  }

  .col-sm-4.illustrationImage img {
    width: 30%;
    height: auto;
  }

  .illustrator .row .col-sm-6 {
    padding: 10px 10px;
    width: 50%;
  }

  .row.illustRow {
    margin-left: 15px;
  }

  .illustrator {
    height: auto;
  }

  .video-container.mobile img {
    width: 100%;
  }

  .policyPage .col-sm-12 h4 {
    font-size: 30px;
  }

  .policyPage .policyContent.col-sm-12 h3 span,
  .policyPage .policyContent.col-sm-12 h3 {
    font-size: 30px !important;
  }

  .policyPage.container .accordion-button {
    font-size: 15px !important;

  }

  .policyPage.container .accordion-button span svg {
    color: #0e76bc !important;
    fill: #0e76bc !important;
    margin: 0 !important;
    margin-right: 10px !important;
    font-size: 20px !important;
  }

  .header .react-reveal.col-sm-6 {
    padding-left: 0 !important;
  }

  .mobile {
    display: block !important;
  }

  .desktop {
    display: none !important;
  }

  .video-container video {
    width: 100% !important;
    height: 100% !important;
    object-fit: unset !important;
  }

  .video-container {
    height: 100% !important;
    width: 100% !important;
    overflow-x: hidden I !important;
  }

  .container.banner:before {
    height: 70% !important;
  }

  .container.banner {
    height: 100vh !important;
  }

  .col-sm-4.side {
    width: 100% !important;
  }

  .col-sm-8.menus {
    width: 100% !important;
  }

  .productmenu h2 {
    font-size: 24px !important;
    font-weight: 800;
  }

  .col-sm-4.side {
    width: 100% !important;
    padding-bottom: 45px;
  }

  .col-sm-4.side h3 {
    font-size: 24px !important;
    color: #0e76bc;
  }

  .productslides .swiper-button-prev {
    left: auto !important;
    right: 22% !important;
    top: 80% !important;
  }

  .menusildes {
    padding: 15px !important;
  }
  .row.prodbox.grooups, .row.prodbox{
    width: 95% !important;
    max-width: 100% !important;
    justify-content: space-evenly !important;
  }
  .tabss .col-sm-12.heads.text .sub.shariahcom.esg p{
    width: 100% !important;
    margin: 0 !important;
    padding: 0 12px !important;
  }
  .lifeCards {
    padding: 20px 0;
  }
  .lifeCards h2 {
    padding-bottom: 20px;
  }
  .sub.productetxt.uniqueprods .row.prodbox{
    margin: 0 auto !important;
  }
  .container.donwloadapps .col-sm-6 {
    max-width: 100% !important;
  }
  .container.tabss.motor.indddddivuaaal {
    padding-top: 30px;
}
  .sub.productetxt.iinndiivuaaal .col-sm-8.writeus, .sub.productetxt.grrrrouupp .row .col-sm-8.writeus{
    padding-left: 8% !important;
    padding-right: 8% !important;
  }
  .sub.productetxt.iinndiivuaaal .col-sm-8.writeus p, .sub.productetxt.grrrrouupp .row .col-sm-8.writeus p{
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin: 0 !important;
  }
  .col-sm-6.mobiles {
    margin-top: 50px !important;
    margin-bottom: 0;
  }
  .row.prodbox.grooups .col-sm-4 {
    height: 280px !important;
}
  .myfooter .footerlogo {
    width: 70px !important;
  }
  .col-sm-4.writeus.indivuuaal{
    margin-left: 0 !important;
  }
  .container.myfooter p {
    width: 100% !important;
    font-size: 14px !important;
    padding: 0 15px;
  }

  input#nest-messages_user_email {
    padding: 10px;
    width: 300px !important;
  }

  .col-sm-6.buttonscta {
    width: 50% !important;
    right: 0%;
  }

  .col-sm-6.buttonscta {
    margin-bottom: 0% !important;
  }

  .col-sm-12.explore h2 {
    font-size: 34px !important;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: auto !important;
    right: 22% !important;
    top: 80% !important;
  }

  .container.homemenu {
    padding: 70px 0 !important;
  }

  .react-reveal.col-sm-6.buttonscta {
    max-width: 100% !important;
  }

  .col-sm-6.buttonscta {
    width: 100% !important;
  }

  h1,
  h2,
  h3,
  h4 {
    position: relative;
    z-index: 999999999;
  }

  .container.myfooter.showtab {
    display: block !important;
  }

  .accordion {
    width: 100% !important;
  }

  .col-sm-12.heads.text.govdirectors .col-sm-4 {
    width: 100%;
  }

  .govdirectors button {
    height: 525px !important;
    margin-bottom: 0px !important;
  }

  .shares img {
    width: 100%;
    margin-bottom: 20px !important;
    margin-top: 0px;
  }

  .shares h2 {
    font-size: 26px !important;
  }

  .col-sm-12.shareholding {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
    text-align: center;
  }

  .motor .shareholding h2 {
    font-size: 30px !important;
  }

  .col-sm-12.shareholding a {
    width: 80% !important;
  }

  .productnav .col-sm-2 {
    margin: auto;
    width: 80%;
  }

  .motor h2 {
    text-align: center;
    color: #0e76bc;
    font-size: 30px !important;
  }

  .motor p {
    width: 100% !important;
  }

  .col-sm-12.heads.text .col-sm-4.why {
    width: 100% !important;
  }

  .row.whychosesalaam {
    width: 90% !important;
    margin: auto;
    padding: 50px 0 !important;
    justify-content: center;
  }

  .col-sm-12.heads.text .col-sm-6.why {
    width: 100% !important;
  }

  .col-sm-6 {
    max-width: 100% !important;
  }

  .motor .claimprocedure .subheading h2 {
    font-size: 22px !important;
    color: #000;
  }

  .nav-link.active img {
    height: 370px;
    object-fit: inherit;
    transition: all 800ms ease-in-out;
    filter: grayscale(0);
    width: 100%;
  }

  .container-fluid.header .col-sm-6 {
    width: 50% !important;
    max-width: 50% !important;
  }

  .menu {
    text-align: end;
  }

  .container.myfooter.showtab {
    padding: 15% 0% !important;
    margin-top: 0%;
  }

  .tab-content .row.tabs {
    padding-bottom: 30px !important;
  }

  .container.productss {
    background: #fff;
    padding: 170px 0 !important;
  }

  .container.individual.productss .col-sm-4 {
    padding-left: 0;
    text-align: center;
    padding-bottom: 15px !important;
  }

  .container.individual.productss .col-sm-4 div {
    font-size: 32px !important;
  }

  .container.individual.productss {
    padding-bottom: 40px !important;
  }

  .container.corporate.productss .col-sm-4 {
    padding-left: 0;
    text-align: center;
    padding-bottom: 15px !important;
  }

  .container.corporate.productss .col-sm-4 div {
    font-size: 32px !important;
  }

  .container.corporate.productss {
    padding-bottom: 40px !important;
    padding-top: 40px !important;
  }

  .productss .swiper-slide h3 {
    font-size: 24px !important;
    text-align: center !important;
  }

  .container.financial.productss .col-sm-4 {
    padding-left: 0;
    text-align: center;
    padding-bottom: 15px !important;
  }

  .container.financial.productss .col-sm-4 div {
    font-size: 32px !important;
  }

  .container.financial.productss {
    padding-bottom: 40px !important;
    padding-top: 40px !important;
  }

  .container.productss .menusildes h3 {
    background: #000;
    font-size: 18px !important;
  }

  th {
    font-size: 26px !important;
  }

  table {
    width: 900px;
  }

  .col-sm-12.takafultable {
    overflow-x: scroll;
  }

  .takafultable th:first-child {
    width: 240px !important;
  }

  html {
    overflow-x: hidden !important;
  }

  body {
    overflow-x: hidden !important;
  }

  .accordion-body {
    overflow-x: scroll;
  }

  .col-sm-12.subheading h2 {
    font-size: 18px !important;
  }

  .col-sm-12.grophowtoclaim {
    padding: 20px 0;
  }

  .row.claimprocedure h2 {
    font-size: 36px;
    text-align: center !important;
  }

  .row.claimprocedure {
    align-items: flex-start !important;
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .react-reveal.container-fluid {
    padding: 0;
  }

  .row.whychosesalaam.gropss .chose h2 {
    font-size: 42px;
  }

  button.accordion-button {
    text-align: left;
  }

  .container.banner:before {
    content: unset;
  }

  .container.mypdfss .col-sm-4.pdfs a img {
    width: 100%;
    margin-left: 0 !important;
  }

  .whoweare p {
    width: 85% !important;
  }

  .menusildes.VIDEEEOS img {
    height: 450px;
    object-fit: cover;
  }




  .row.whoweare img {
    margin-bottom: 25px;
  }

  .row.whoweare.mission {
    flex-direction: column !important;
  }

  .vision li {
    padding-right: 0% !important;
  }

  .retaakaful {
    margin: 20px 0 !important;
    margin-bottom: 20px !important;
  }

  .col-sm-12.heads.text.news {
    width: 100% !important;
    margin: auto;
    padding: 120px 0;
  }

  .text.news .col-sm-4 {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 15px;
    width: 90%;
    padding: 10px;
    border-radius: 10px;
    margin: auto !important;
    margin-bottom: 50px !important;
  }

  .heads.text.mymaxheight.complainbox .formstyle p.message {
    width: 100% !important;
  }

  .react-reveal .audit h3 {
    width: 100% !important;
  }

  .container.homemenu .col-sm-8.menus {
    padding-right: 12px;
  }

  .statment button.accordion-button {
    font-size: 18px !important;
  }

  .row.greenbox {
    justify-content: center !important;
  }

  .row.greenbox .col-sm-3 {
    width: 85% !important;
  }

  .container.boxes {
    max-width: 100% !important;
  }

  .sub.shariahcom {
    width: 99% !important;
    margin: auto;
    text-align: center;
  }

  .sub.shariahcom p.aos-init.aos-animate {
    font-size: 14px !important;
  }

  ::-webkit-scrollbar {
    height: 2px;
  }

  .shariahtabs ul.mb-3.nav.nav-tabs.nav-fill {
    width: 100%;
    margin-bottom: 5% !important;
    overflow-x: scroll;
    display: flex;
    flex-wrap: inherit;
    justify-content: flex-start;
    overflow-y: hidden;
  }

  .heads .sub.shariahtabs li.nav-item {
    width: 60%;
    flex: 0 0 50%;
  }

  .row.tabscss img {
    height: 100% !important;
    object-fit: cover;
    margin-left: 0 !important;
}

  .tabscss .col-sm-8 {
    flex: 0 0 auto;
    width: 100% !important;
  }

  .tabss .col-sm-12.heads.text h3 {
    font-size: 22px !important;
    padding-bottom: 10px;
    font-weight: 700 !important;
    padding-top: 10px;
  }

  .row.tabscss {
    width: 95% !important;
    margin: auto;
    align-items: center !important;
    margin-bottom: 5% !important;
  }

  .row.tabscss.left {
    flex-direction: column-reverse;
  }

  .row.tabscss img {
    margin-bottom: 20px;
  }

  .tab-content ul {
    padding-left: 10px !important;
  }

  .col-sm-3.write img {
    width: 30% !important;
  }

  .col-sm-3.write {
    text-align: center;
  }

  .col-sm-4.writeus {
    padding: 10% !important;
  }

  .col-sm-4.cardcta {
    padding: 10% 8% !important;
  }

  .tabss.motor .col-sm-12.heads.text .sub.surplus p {
    font-size: 14px !important;
  }

  .react-reveal.col-sm-9.writeus {
    padding-bottom: 30px;
  }

  .col-sm-6.explore {
    padding-left: 15px;
  }

  .col-sm-6.explore p {
    width: 100%;
    padding-top: 10px;
  }
  .savingssec .col-sm-6.explore video{
    min-height: 100% !important;
  }

  .col-sm-6.explore img {
    padding-bottom: 30px;
  }
  .savingssec .col-sm-6.explore img{
    height: 200px !important;
    padding-bottom: 10px !important;
  }

  .sub.productetxt .col-sm-8 {
    width: 100% !important;
    padding-left: 5%;
    padding-right: 8%;
  }

  .row.prodbox .col-sm-4 {
    width: 95%;
  }

  .shares.riders img {
    margin-top: 0 !important;
  }

  .shares.riders .react-reveal.col-sm-7 {
    padding-left: 0 !important;
  }

  .row.whoweare h2 {
    padding-bottom: 5%;
    padding-left: 0 !important;
  }

  .whoweare p {
    width: 90% !important;
    margin-left: 30px !important;
  }

  .row.whoweare.mission.left {
    display: flex;
    flex-direction: column-reverse !important;
  }

  .core .vision {
    padding-right: 0 !important;
  }
  .companyProfile .col-sm-6 {
    margin: 10px 0;
  }
  /* .row.addsa.featureRow .col-sm-3 {
    width: 100% !important;
} */

.tabss .col-sm-12.heads.text .row.addsa.featureRow .col-sm-3 p{
  font-size: 13px !important;
}
  .container-fluid.shares.marbot.mufti img {
    width: 80%;
    margin-bottom: 0 !important;
  }
  .container.homemenu .col-sm-4.side {
    padding: 0 30px 50px 31px !important;
}
  .container-fluid.shares.marbot img {
    width: 80%;
    margin-bottom: 0px !important;
  }
  .col-sm-12.shareholding.myntn {
    padding: 0 !important;
}
  .container.governancemain.motor .shares img {
    width: 100%;
    margin-bottom: 10px !important;
  }
  .container-fluid.shares .row.mySuppliment.sumpleRider{
    width: 100% !important;
  }

  .shares {
    background-color: #0fb583;
    padding-top: 50px;
    padding-bottom: 50px !important;
  }

  .text.mymaxheight .row.prodbox p {
    font-size: 14px !important;
  }

  .row.prodbox .col-sm-4 {
    height: 340px !important;
  }

  .row.whoweare.codeofconduct p.aos-init.aos-animate {
    width: 90% !important;
    margin-left: 25px !important;
  }

  .row.whoweare.codeofconduct h2 {
    width: 86% !important;
    text-align: left;
    margin: auto;
    padding-left: 0;
    padding-bottom: 6%;
    padding-top: 2%;
  }

  .patern li {
    width: 100% !important;
  }

  .govdirectors {
    padding: 60px 0 !important;
  }

  .header .react-reveal.col-sm-3 {
    width: 50%;
  }

  .header .react-reveal.col-sm-9 {
    width: 50%;
  }

  .container-fluid.header svg {
    fill: #fff !important;
    color: #fff;
    font-size: 32px !important;
    margin-left: 0px;
  }

  .sub.complainforms.whistleforms.fundPrices {
    width: 95% !important;
  }

  .sub.complainforms.whistleforms.fundPrices th.ant-table-cell {
    padding: 10px 0 !important;
    font-size: 15px !important;
  }

  td.ant-table-cell {
    padding: 10px 0 !important;
    text-align: center !important;
    font-size: 12px !important;
  }

  svg {
    fill: #fff !important;
    color: #fff;
    font-size: 38px !important;
    margin-left: 25%;
  }

  .col-sm-4.side {
    width: 100% !important;
    padding-bottom: 60px;
    padding-left: 29px;
  }

  .row .col-sm-6 {
    flex: 0 0 auto;
    width: 100%;
  }

  .newbtn .blue {
    background-color: #0e76bc;
    color: #fff;
    font-size: 12px;
  }

  .newbtn .gray {
    background-color: #e0e0e0;
    color: #000;
    font-size: 12px;
  }

  .col-sm-12.genResBtn {
    padding: 0 12px;
  }

  .sub.complainforms.whistleforms.fundPrices .col-sm-12.formstyle.rightform {
    padding: 0 15px;
  }

  .col-sm-12.mediaCenterHeading h2 {
    padding: 50px 0 30px;
  }

  .sub.complainforms.whistleforms.pastPriceForm {
    width: 90%;
    background: #0889e3;
    padding: 20px 12px;
    border-radius: 20px;
    margin: 30px auto 50px;
  }

  .newbtn {
    padding-top: 16px;
    /* margin-left: 4%; */
  }

  .swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu .swiper-button-prev {
    right: 15% !important;
  }

  .donwloadapps .buy {
    padding: 15px 20px;
    font-size: 11px;
  }

  .container.banner .react-reveal {
    padding-right: 1px !important;
  }

  .col-sm-6.buttonscta .call {
    padding: 12px 15px !important;
    font-size: 12px;
  }

  .col-sm-6.explore h3.salamProdMenu {
    color: #fff;
    font-size: 24px !important;
  }

  .col-sm-6.buttonscta .buy {
    padding: 3px 12px !important;
    font-size: 12px;
  }

  .col-sm-6.buttonscta .buy {
    padding: 2px 8px !important;
    font-size: 12px;
  }
  .col-sm-4.side .buy {
    margin-left: -1%;
  }
  .row .button-container {
    margin-left: 4%;
    font-size: 11px;
  }

  .donwloadapps .buy {
    padding: 15px 14px;
    font-size: 11px;
  }

  .container.faderight.menuside {
    height: 100%;
    padding-top: 25%;
  }

  .row .col-sm-12.shareholding a {
    margin-left: 8%;
    width: 14%;
    margin-top: 18%;
  }

  .row.whoweare.codeofconduct h2 {
    width: 86% !important;
    text-align: left;
    margin: auto;
    padding-left: 0;
    padding-bottom: 6%;
    padding-top: 8%;
  }

  .sub.productetxt .col-sm-8 {
    width: 100% !important;
    padding-left: 13%;
    padding-right: 8%;
  }

  .col-sm-12 .row.prodbox .col-sm-4 {
    padding: 20px 12px;
    width: calc(50% - 15px);
    margin: 10px 1px;
}

  .react-reveal.col-sm-4.ctaimgae img {
    margin-left: 0 !important;
  }

  .sub.cta .col-sm-4.writeus {
    padding: 10% !important;
  }

  .Buynowform h4.oswald {
    padding-top: 30px;
  }

  .row.statement.uploader svg {
    fill: #000 !important;

  }

  .react-reveal.container.tabss.motor.buynow {
    background-color: #0fb583;
    padding: 35% 5% !important;
    margin-top: 0 !important;
  }

  .react-reveal.container.tabss.motor.buynow img {
    margin-left: 0 !important;
  }

  .sub.cta .col-sm-4.writeus img {
    margin-left: 0 !important;
  }

  .col-sm-3.write {
    width: 60%;
    margin: auto;
  }

  .claimprocedure img {
    width: auto !important;
    margin-right: -1px;
  }

  .col-sm-12 .claimprocedure li {
    padding-left: 7px !important;
    padding: 7%;
  }

  .tabss .col-sm-12.heads.text p {
    font-size: 16px !important;
    padding-bottom: 0 !important;
    overflow-y: scroll;
    margin-left: 8%;
  }

  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>td,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>td,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>td,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>td,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>th,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>th,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>th,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>th,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td {
    border-inline-end: 4px solid #f0f0f0;
    padding: 17px;
  }

  li,
  span,
  p,
  td {
    font-size: 13.1px !important;
  }

  th {
    font-size: 22px !important;
    text-align: left;
  }

  .fundPrices table {
    text-align: left;
    width: 100%;
  }

  .ant-table-content {
    overflow-x: scroll;
  }

  .col-sm-12.heads.text.mymaxheight.complainbox.whistle.fundtable {
    padding: 3% 0;
    margin: 0% 0;
    margin-left: 0%;
  }

  .col-sm-4 .react-card-front button img {
    height: 440px;
    object-fit: cover;
    margin-right: 0;
  }

  .col-sm-4 .react-card-front button img {
    width: 100%;
    margin-top: 0%;
  }

  .col-sm-7.visionVideo {
    margin-top: 80px;
  }

  .tabss .col-sm-12.heads.text p {
    font-size: 16px !important;
    /* height: 150px; */
    padding-bottom: 0 !important;
    overflow-y: scroll;
    margin-left: 1%;
  }

  .col-sm-5.allvisions {
    padding: 5% 5%;
  }

  .col-sm-4.writeus {
    padding: 10% !important;
    margin-left: 8%;
  }

  h2.react-reveal {
    line-height: 1.2;
  }

  .container-fluid.shares.marbot h3 {
    width: 100% !important;
    text-align: center;
    line-height: 30px;
  }

  .tabss img {
    margin-left: -10%;
    margin-top: 0%;
  }

  li,
  span,
  p,
  td {
    font-size: 14.1px !important;
  }

  .col-sm-12 .claimprocedure li {
    padding-left: 16px !important;
    padding: 7%;
  }

  .react-reveal.row.claimprocedure h2 {
    width: 103% !important;
    text-align: left !important;
    color: #0e76bc !important;
  }

  .tabss .col-sm-12.heads.text .shares.riders h3 {
    font-size: 23px !important;
    margin-left: 0;
  }

  .app-buttons img {
    width: 140px !important;
    margin-top: -20% !important;
  }

  .container.donwloadapps .col-sm-6.mobiles img {
    width: 100% !important;
    margin-top: 0 !important;
  }

  .row .col-sm-6.buttonscta {
    bottom: 10px;
  }
  .companyProfile .col-sm-6 p {
    font-size: 13px !important;
}
.companyProfile .col-sm-6 h2 {
  font-size: 20px;
}
  .container-fluid.shares.marbot p {
    width: 100% !important;
    text-align: left;
    margin-left: 0% !important;
  }

  .container-fluid.shares.marbot h2 {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .col-sm-12.heads.adds.firstpart h2 {
    width: 100%;
    padding: 0 0 30px 25px !important;
  }

  .col-sm-12.heads.adds.firstpart .whoweare p {
    width: 100% !important;
    padding: 0 20px 30px 25px !important;
    margin-left: 0 !important;
  }

  .row .govdirectors {
    padding: 0px 0 !important;
  }

  .sub.complainforms.whistleforms.fundPrices {
    width: 100% !important;
    padding: 0 12px;
  }

  button.accordion-button {
    padding: 10px 15px;
    border-radius: 5px !important;
  }

  .sub.complainforms {
    padding: 0 0 40px;
  }

  .sub.complainforms.whistleforms.pastPriceForm {
    width: 80%;
  }

  .mobileMenu {
    padding-bottom: 30px;
  }

  .tabss .col-sm-12.heads.text .sub.shariahcom.moreePadd p {
    font-size: 16px !important;
  }

  .formstyle input {
    width: 100% !important;
    padding: 12px 10px !important;
  }

  .sub.shariahcom.moreePadd {
    width: 80% !important;
  }

  .col-sm-12.heads.text.mymaxheight.complainbox .col-sm-6 img {
    margin-left: 0 !important;
  }

  .col-sm-10.dashboardSection {
    height: 100%;
  }

  .combtn {
    width: 80%;
    margin: auto;
  }

  .sub.complainforms.whistleforms.fundPrices {
    width: 100%;
    padding: 30px 10px 15px;
  }

  .tabss .col-sm-12.heads.text .col-sm-12.formstyle.rightform h3 {
    font-size: 28px !important;
  }

  .col-sm-12.formstyle.rightform h2 {
    font-size: 22px !important;
    padding-top: 50px;
  }

  table th {
    font-size: 18px !IMPORTANT;
  }

  .sub.productetxt.uniqueprods h2 {
    text-align: center !important;
    font-size: 32px !important;
  }

  .row.addsa img {
    width: 60% !important;
    margin: 0;
  }

  .row.mySuppliment {
    padding: 0px;
  }

  .riders .suplimentsicon .supliment .mySuppliment img {
    width: 45% !important;
  }

  .row.mynewboxes {
    width: 100%;
    margin: auto;
    margin-bottom: 10%;
  }

  .row.mynewboxes .col-sm-6 {
    background: #1B75BB;
    padding: 25px;
    border-radius: 50px 20px;
    margin: 0 !important;
    width: 100% !important;
    min-height: 250px !important;
    height: auto !important;
    margin-bottom: 15px !important;
  }

  .tabss .col-sm-12.heads.text .row.mynewboxes h3 {
    font-size: 24px !important;
  }

  .row.mynewboxes h4 {
    font-size: 16px;
    font-weight: 800;
  }
  .row.addsa.myadongroup{
    align-items: flex-start !important;
  }
  .row.addsa.myadongroup .col-sm-3 {
    width: calc(50% - 10px) !important;
    height: auto !important;
    min-height: 200px;
    margin: 5px;
    padding: 20px 10px;
  }

  .combtn {
    padding: 0 !important;

  }

  .navbar-expand-lg .navbar-nav .nav-link {
    margin-top: 10px;
    margin-left: 2px;
  }

  #directors .col-sm-4:nth-child(1) {
    width: 100% !important;
  }

  #directors .col-sm-4:nth-child(2) {
    width: 100% !important;
  }

  .govdirectors .col-sm-4 .react-card-flipper {
    text-align: center !important;
  }
  .col-sm-4 .react-card-front button img {
    margin-right: 0 !important;
  }

  .govdirectors button.backbox {
    height: 100% !important;
    margin-top: 0px !important;
    overflow-y: scroll !important;
  }

  div#managment {
    margin-top: 18%;
  }

  button.backbox p {
    position: relative;
    z-index: 1;
  }

  .react-card-front {
    z-index: 999;
    background-color: #fff;
  }

  div#directors h2 {
    padding: 35px 0;
  }
}

@media only screen and (max-width: 360px) {
  .newbtn .gray {
    background-color: #e0e0e0;
    color: #000;
    font-size: 12px;
    margin-top: 10px;
  }
}