@media only screen and (max-width: 1550px) {
  .container.banner h2 {
    text-align: left;
    color: #04a753;
    font-size: 42px;
  }
  .col-sm-12.eligNumber{
    margin-bottom: 10px;
  }
  .motor h2.shariahhead {
    font-size: 42px !important;
  }
  .react-reveal.col-sm-8.writeus {
    margin-bottom: 15px !important;
  }
  .row.profile .col-sm-6 p {
    width: 100% !important;
  }
  .vision p {
    font-size: 16px;
  }
  .aboutContent h2 {
    margin-left: 30px !important;
  }

  .container-fluid.shares.marbot p {
    margin-left: 0 !important;
    width: 90% !important;
  }
  .swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu .swiper-button-next {
    right: 1% !important;
  }
  .swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu .swiper-button-next:after, .swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu .swiper-button-prev:after {
    font-size: 18px !important;
  }
  .secp-img img {
    width: 100%;
  }

  input#nest-messages_user_email {
    padding: 10px;
    width: 250px;
  }

  .container-fluid.shares.marbot h2 {
    margin-left: 0 !important;
    width: 80% !important;
  }

  .container-fluid.shares.marbot h3 {
    margin-left: 0 !important;
    width: 80% !important;
  }

  .tabss .col-sm-12.heads.text .sub.productetxt .col-sm-8 p {
    width: 100% !important;
    font-size: 14px !important;
  }

  .aboutContent h3 {
    margin-left: 30px !important;
  }
  .container.governancemain.motor .heads.govbanner h2, .container.governancemain.motor .heads.govbanner1 h2{ 
    font-size: 46px !important;
  }
  
  .container.homemenu {
    height: 100%;
    background: #fff;
    padding: 120px 0 !important;
  }
  .swiper-slide .menusildes h3 {
    font-size: 21px !important;
    text-align: left;
  }
  .donwloadapps h2 {
    font-size: 40px !important;
    color: #0e76bc !important;
    padding-bottom: 10px;
  }
  /* .button-container.app-buttons {
    margin-top: 14% !important;
  } */
  .container.donwloadapps .col-sm-6.mobiles img {
    width: 120% !important;
    margin-top: -40%;
  }
  .motor h2.posthead {
    font-size: 32px !important;
    padding-bottom: 20px;
  }
  .sidenews{
    margin: 0 !important;
  }
  .sidenews h4 p{
    font-size: 14px;
  }
  .sidenews .row{
    align-items: flex-start !important;
  }
  .googletranslatee {
    top: 90px !important;
    right: 12px !important;
  }
  .col-sm-6.buttonscta {
    margin-top: 10% !important;
    margin-bottom: 0% !important;
  }
  .motor .col-sm-9.singlenews h2 {
    font-size: 32px !important;
    padding: 30px 30px;
    padding-top: 30px;
  }
  .col-sm-4.side h3 {
    font-size: 26px !important;
    color: #0e76bc;
  }
  .video-container video {
    object-fit: fill !important;
  }
  .savingssec .col-sm-6.explore img {
    margin-bottom: 10px;
    height: 350px !important;
  }
  .savingssec .col-sm-6.explore video{
    min-height: 300px !important;
  }
  .container.homemenu .col-sm-4.side {
    padding-left: 50px !important;
  }
  .col-sm-4.side .buy {
    padding: 13px 20px !important;
    font-size: 15px !important;
  }
  .col-sm-4.side li {
    font-size: 18px !important;
    padding-bottom: 18px !important;
  }
  .col-sm-12.shareholding.myntn p {
    margin: 0 auto !important;
  }
  .productsslides h3 {
    font-size: 22px;
    color: #092854 !important;
  }

  .productsslides img {
    padding-bottom: 24px;
  }

  .productsslides p.categories {
    padding-top: 12px;
  }   
  .myfooter p {
    width: 88%;
  }
  .sidenews h4 p{
    font-size: 11px;
}
  
    .motor p {
      font-size: 15px !important;
  }  
  .col-sm-12.heads.adds.firstpart {
    padding: 50px 0;
  }
  .nav-item.dropdown {
    padding: 0 12px !important;
  }
  .col-sm-9.productslides {
    width: 90%;
  }

  .nav-item.dropdown a {
    font-size: 16px !important;
  }
  .container.homemenu {
    padding: 120px 0;
  }
  .formstyle h2 {
    font-size: 32px !important;
  }
  .swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu.swiper-backface-hidden .swiper-wrapper {
    height: auto;
  }
  .productmenu h2 {
    padding-bottom: 70px !important;
  }
  .newbtn a {
    padding: 10px 20px;
    font-size: 15px !important;
  }
  .newbtn a:hover{
    padding: 10px 25px;
  }
  .col-sm-6.explore p {
    font-size: 16px !important;
  }
  th.ant-table-cell {
    font-size: medium;
  }
  .col-sm-6.explore h3 {
    font-size: 28px !important;
  }
  .container.homemenu h2 {
    padding: 35px 0;
  }

  .menusildes h3 {
    margin-top: -35px !important;
    font-size: 18px !important;
  }

  .container.productss {
    padding-bottom: 170px;
  }

  .container.corporate.productss,
  .container.financial.productss {
    padding-top: 170px;
  }

  .row.claimprocedure {
    padding: 7% 5%;
  }

  .container.productnav a {
    height: 70px;
  }

  .row.whychosesalaam {
    padding: 70px 0;
  }

  .swiper-slide h3 {
    font-size: 18px !important;
    text-align: left;
  }

  .swiper-slide p {
    width: 100% !important;
  }
  .row.contactLinks .col-sm-4 a {
    font-size: 22px;
  }
  .row.contactLinks svg {
    height: 85px;
    margin-bottom: 10px;
  }
  .motor .col-sm-12.heads.text.mymaxheight.bot .swiper-button-prev {
    top: 95% !important;
    right: 5% !important;
  }
  .row.contactLinks .col-sm-4 h3 {
    font-size: 26px !important;
  }
  body {
    overflow-x: hidden !important;
  }
  html {
    overflow-x: hidden !important;
  }
  .col-sm-4.vidostak h4 {
    font-size: 18px !important;
}
  .col-sm-12.heads.text.retaakaful {
    margin-top: 1px;
  }
  .retak h2.aos-init.aos-animate {
    font-size: 42px;
  }

  .text.news .col-sm-4 {
    margin: 10px !important;
  }

  .text.news .menusildes h4 {
    font-size: 18px !important;
    margin-bottom: 0;
  }

  .row.greenbox p {
    font-size: 36px !important;
    line-height: 55px !important;
  }

  .row.greenbox a {
    font-size: 30px !important;
    line-height: 55px !important;
  }

  .statment .accordion-body p {
    font-size: 22px !important;
  }

  .row.statment h2 {
    font-size: 38px;
  }

  .statment button.accordion-button {
    font-size: 24px;
  }

  .sub.shariahcom {
    width: 85% !important;
    margin: auto;
    text-align: center;
  }

  .sub.shariahcom p {
    padding-bottom: 0 !important;
  }

  .motor h2 {
    color: #0e76bc;
    font-size: 38px !important;
  }
  .row.profile {
    padding-top: 5px !important;
  }

  .tabss .col-sm-12.heads.text h3 {
    font-size: 22px !important;
  }

  .col-sm-4.cardcta {
    background: #0e76bc;
    padding: 6% 5% !important;
    margin-top: -0.6%;
  }

  .col-sm-4.writeus {
    padding: 4% !important;
  }

  .sub.surplus {
    width: 90% !important;
  }

  .tabss .col-sm-12.heads.text .sub.productetxt .col-sm-8 p {
    width: 100% !important;
    font-size: 14px !important;
  }

  .sub.productetxt.uniqueprods h2 {
    text-align: center !important;
    font-size: 38px !important;
  }

  .tabss .col-sm-12.heads .row.prodbox .col-sm-4 h3 {
    padding-bottom: 0 !important;
    transition: all 300ms ease-in-out;
    font-size: 20px !important;
  }
  .tabss .col-sm-12.heads.text.mymaxheight.po .row.mynewboxes h3{
    font-size: 32px !important;
  }
  .tabss .col-sm-12.heads.text.mymaxheight.po .row.mynewboxes h4, .tabss .col-sm-12.heads.text.mymaxheight.po .row.addsa.myadongroup h4{
    font-size: 21px !important;
  }
  .col-sm-12.eligNumber div h1 {
    font-size: 55px !important;
    padding: 20px 0 !important;
  }
  .dropdown-menu.show .dropdown-menu.show {
    left: 106%;
  }
  .tabss .col-sm-12.heads.text .row.mySuppliment h3 {
    font-size: 22px !important;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .dropdown-menu.show .dropdown-menu.show {
    left: 106%;
  }
  .col-sm-12.newnoPad p{
    width: 100%;
  }
  .tabss .col-sm-12.heads.text.mymaxheight.po .row.addsa.myadongroup p{
    font-size: 16px !important;
  }
  .tabss .col-sm-12.heads.text.mymaxheight.widraw.po .col-sm-12.formstyle.rightform h3{
    font-size: 40px !important;
    padding-top: 20px !important;
  }
  .col-sm-12.heads.text.mymaxheight.widraw.po {
    padding: 50px 0;
}
  .col-sm-12.heads.text.mymaxheight.widraw.po .formstyle h2{
    font-size: 32px !important;
    padding-top: 3%;
  }
  .row.prodbox {
    max-width: 90% !important;
    margin: auto;
    padding-top: 0 !important;
    padding-bottom: 50px;
  }

  .row.prodbox .col-sm-4 {
    height: 400px !important;
  }

  .container-fluid.shares .row {
    width: 95% !important;
    margin: auto;
  }

  .tabss .col-sm-12.heads.text .shares.riders h3 {
    font-size: 21px !important;
  }

  .supliment {
    padding-bottom: 20px !important;
  }

  .myfooter h3 {
    color: #fff !important;
    padding-bottom: 15px;
    font-size: 24px;
  }

  .nav-item.dropdown a {
    font-size: 14px !important;
    padding: 0 !important;
  }

  .header a.nav-link a {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    padding-top: 0 !important;
  }

  .donwloadapps .buy {
    background-color: #04a753 !important;
    color: #fff !important;
    padding: 15px 27px;
    border-radius: 10px;
    width: 40%;
    text-align: center;
  }

  .row .col-sm-6.buttonscta {
    background: transparent !important;
    margin: 1px !important;
    width: 23%;
    text-align: end;
    justify-content: end;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999999999999;
  }

  .row .dropdown-menu.show {
    padding: 8px;
  }
  .row.recommendations.buttonss{
    justify-content: space-between;
  }
  .row.recommendations.buttonss .col-sm-3.NOLEFT{
    width: 50%;
    padding: 0 12px 0 0 !important;
  }
  .col-sm-4.illustrationCheckkkkk {
    width: 50% !important;
  }
  .col-sm-3.fullWidth{
    width: 50% ;
    padding-bottom: 20px;
  }
  .col-sm-3.fullWidth span.ant-upload-wrapper svg{
    padding-bottom: 0 !important;
    width: 25px;
    height: 25px;
  }
  .col-sm-3.fullWidth span.ant-upload-wrapper, .col-sm-3.fullWidth .ant-upload.ant-upload-select, .col-sm-3.fullWidth.uploadsbtns .ant-form-item button {
    width: 100% !important;
  }
  .row.recommendations.buttonss .col-sm-3.NOrIGHTT{
    width: 50%;
    padding: 0 !important;
  }
  .row.recommendations.buttonss .col-sm-5{
    width: 100%;
    padding: 0;
  }
  .row.recommendations.buttonss.less{
    display: none !important;
  }
  .row.recommendations.buttonss.more{
    display: flex !important;
  }
  .row.recommendations.buttonss .col-sm-3 button, .row.recommendations.buttonss .col-sm-5 button{
    width: 100% !important;
    margin-bottom: 10px;
    padding: 0;
  }
  .dropdown-menu.show a {
    color: #fff;
    font-weight: 500;
    font-size: 14px !important;
    padding: 10px;
    margin-top: 8%;
  }

  .header a.nav-link a {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
  }

  .col-sm-6 .buy {
    margin: 0 4px;
  }

  .col-sm-6.buttonscta.container.banner .react-reveal {
    padding-right: 2px !important;
  }

  .col-sm-12 .row.prodbox .col-sm-4 {
    opacity: 1;
    padding: 36px;
    width: 30%;
    margin: 10px 17px;
  }
  .dropdown-menu.show .dropdown-menu.show {
    left: 108%;
  }
  .header a.nav-link a {
    color: #fff;
    font-weight: 500;
    font-size: 14px !important;
  }

  a.nav-link {
    padding-top: 0;
  }
  .row .col-sm-6.formstyle.rightform {
    padding: 3.8%;
  }
  .container.foots.banner .row .col-sm-6.buttonscta .buy {
    padding: 3px 10px !important;
  }
  .f {
    left: 5% !important;
    margin: 0 -1px;
  }
  .sideBarSec a p {
    padding-top: 30px !important;
    text-align: center;
    font-size: 14px !important;
  }
  .sideBarSec {
    width: 100% !important;
  }
  .f img {
    margin: 0px 7px !important;
  }
  .row.profile .col-sm-6 h2 {
    font-size: 25px !important;
  }
  .dashboardTabs .box p {
    margin: 0 auto;
    width: 100% !important;
    text-align: center;
    padding: 0;
  }
  .dashboardTabs .box {
    width: 100%;
  }
  .row.steptext .col-sm-8 p {
    width: 100% !important;
  }
  .container-fluid.stepsform .react-reveal.col-sm-6 {
    padding-right: 2px;
  }
  .healthSection.container .react-reveal.col-sm-12 p {
    width: 100% !important;
    padding-left: 0%;
    padding-right: 4%;
    font-size: 14px;
  }
  .ratingSection .col-sm-6 .row .col-sm-9 p {
    color: #a39e9e;
    font-size: 13px;
    font-weight: 400;
    padding-top: 15px;
    padding: 0;
    margin: 0;
    font-size: 14px !important;
    padding: 4px;
  }
  .react-reveal.col-sm-6 p, .col-sm-7.audp.aboutContent p {
    font-size: 15px !important;
  }
  .ratingSection .col-sm-6 .row .col-sm-9 p {
    color: #a39e9e;
    font-size: 13px;
    font-weight: 400;
    padding-top: 0px !important;
  }
  .react-reveal.col-sm-12.termsSection p {
    width: 100% !important;
  }
  .illustrator h6 {
    font-size: 14px;
  }
  .col-sm-12.step6Tab th {
    font-size: 13px !important;
  }
  .col-sm-12.step6Tab td {
    padding: 20px 10px;
    font-size: 13px !important;
  }
  .donwloadapps ul li {
    font-size: 14px !important;
  }
  .container-fluid.shares.marbot .col-sm-7.audp h2 {
    text-align: left !important;
    color: #fff;
  }

  .container-fluid.shares.marbot.mufti .row .col-sm-12 h2 {
    width: 100% !important;
  }

  .userSidebar {
    max-height: 84.8vh !important;
  }
  .dashbord {
    min-height: 84.3vh !important;
  }
  a.buttoncalim {
    background: #0c551d;
    padding: 0 !important;
    border-radius: 10px;
    transition: all 300ms ease-in-out;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px !important;
    width: 100px;
    justify-content: center;
    color: #fff;
  }
  a.nav-link {
    padding: 0px 10px !important;
  }
  .sidenews h4 p{
    font-size: 14px !important;
    font-weight: 600 !important;
}
.sidenews p {
  font-size: 12px !important;
  margin-top: 5px !important;
}
.motor .col-sm-9.singlenews h2, .motor h2.posthead {
  font-size: 32px !important;
}
}

@media only screen and (max-width: 1450px) {
  .nav-link h4 {
    font-size: 14px !important;
    padding: 10px 5px;
  }
  .col-sm-12.mediaCenterHeading h2 {
    padding: 50px 0 20px !important;
  }
  .menusildes.VIDEEEOS img {
    height: 490px !important;
  }
  .tabss .col-sm-12.heads.text.mymaxheight .row.tabscss.shhaariahAdvisor h3{
    padding-bottom: 0;
  }
  .govdirectors button.backbox {
    height: 600px !important;
  }
  .col-sm-3.benefitBox p {
    font-size: 9px !important;
  }
  input#nest-messages_user_email {
    padding: 10px;
    width: 230px;
  }

  .visionVideo video {
    width: 100%;
    margin-right: 0px !important;
  }

  nav a.nav-link {
    display: block !important;
  }

  .myfooter p {
    color: #ffffff7d !important;
    width: 90% !important;
    font-size: 14px !important;
  }
  .myfooter a {
    font-size: 14px !important;
  }
  .header img {
    width: 60px !important;
  }
  .motor .swiper-button-prev {
    right: 4% !important;
  }

  .nav-item.dropdown a {
    font-size: 14px !important;
  }

  .statment button.accordion-button {
    font-size: 24px;
  }

  .col-sm-4.cardcta {
    background: #0e76bc;
    padding: 5.5% 5% !important;
    margin-top: -0.6%;
  }

  .col-sm-4.writeus {
    padding: 4% !important;
  }

  .tabss.motor .col-sm-12.heads.text .sub.surplus p {
    font-size: 16px !important;
  }

  .col-sm-6.buttonscta .buy {
    padding: 12px 20px !important;
  }

  /* changes made */
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
    padding: 4px;
  }

  .col-sm-9 p {
    margin-top: 10px;
  }

  .col-sm-9 .navbar-nav .dropdown-menu {
    position: static;
    padding: 0px;
  }

  .nav-item.dropdown a {
    font-size: 14px !important;
    padding: 5px !important;
  }

  .dropdown-menu.show a {
    margin-top: 11px;
    margin-left: 1px;
    margin-bottom: 11px;
  }

  .dropdown-menu.show {
    background: #0e76bc !important;
    margin-left: -18%;
  }
  .tabss img {
    width: 142% !important;
    margin-left: -6%;
    margin-top: -21%;
  }
  .row.prodbox {
    padding-top: 5%;
  }

  .claimprocedure img {
    width: auto !important;
    margin-right: 8px;
  }
  .tabss .col-sm-12.heads .row.prodbox .col-sm-4 h3 {
    font-size: 18px !important;
  }
  .sub {
    padding-bottom: 50px;
  }
  .tabss .col-sm-12.heads.text .row.addsa .col-sm-3 p {
    font-size: 15px !important;
  }
  .tabss img {
    width: 142% !important;
    margin-left: -2%;
    margin-top: 0%;
  }

  .col-sm-12 .claimprocedure li {
    padding-left: 24px !important;
    padding: 1%;
  }

  .col-sm-8 p {
    width: 66.66666667% !important;
    padding-left: 0%;
    padding-right: 4%;
    font-size: 50px;
  }
  .col-sm-7 .supliment {
    padding-bottom: 21px !important;
  }

  .container.homemenu h2 {
    font-size: 52px;
    color: #fff;
    letter-spacing: 7px;
    padding: 51px 0;
    text-align: center;
    margin-top: -7%;
  }
  .col-sm-4 h3.dname {
    text-align: left;
    color: #0e76bc;
    font-weight: 700;
    padding-top: 13px;
    padding-left: 40px;
    margin-bottom: 0;
    font-size: 20px;
  }
  .tabss .col-sm-12.heads.text h3 {
    font-size: 23px !important;
  }
  .tabss .col-sm-12.heads.text.mymaxheight.po .row.mynewboxes h3 {
    font-size: 28px !important;
  }
  .tabss .col-sm-12.heads.text.mymaxheight.po .row.mynewboxes h4, .tabss .col-sm-12.heads.text.mymaxheight.po .row.addsa.myadongroup h4 {
    font-size: 18px !important;
  }
  .tabss .col-sm-12.heads.text p {
    font-size: 14px !important;
  }
  .tabss .col-sm-12.heads.text.mymaxheight.po .row.addsa.myadongroup p {
    font-size: 14px !important;
  }
  .col-sm-12.eligNumber div h1 {
    font-size: 46px !important;
  }
  .row.mySuppliment {
    padding: 36px !important;
    margin: 0 !important;
  }
  .tabss .col-sm-12.heads.text .riders .suplimentsicon .supliment .mySuppliment h3 {
    margin: 12px 0;
  }
  .tabss .col-sm-12.heads.text .sub.shariahcom.moreePadd p.newAliggn {
    font-size: 16px !important;
  }
  .riders .suplimentsicon .supliment .mySuppliment img {
    width: 30% !important;
  }
  .col-sm-4 p.designation {
    padding: 0;
    margin: 0px;
    text-align: left;
    padding-left: 41px;
    font-weight: 500;
    font-size: 22px;
    color: #4b4b4b;
    text-transform: capitalize;
  }

  .app-buttons img {
    width: 165px !important;
  }

  .col-sm-4 .react-card-front button img {
    width: 80%;
  }
  .col-sm-12 .whoweare p {
    margin: -13px !important;
    padding: 0px !important;
    margin-left: 10% !important;
    padding-bottom: 30px !important;
    font-size: 16px !important;
  }
  .container-fluid.shares.marbot p {
    font-size: 15px;
  }
  .motor h2 {
    font-size: 34px !important;
  }
  .companyProfile .col-sm-6 h2 {
    font-size: 26px;
  }
  .col-sm-12.heads.text.mymaxheight.widraw.po .formstyle h2 {
    font-size: 26px !important;
  }
  .tabss .col-sm-12.heads.text.mymaxheight.widraw.po .col-sm-12.formstyle.rightform h3 {
    font-size: 32px !important;
  }
  .companyProfile .col-sm-6 p {
    font-size: 14px;
  }
  .sub.productetxt.uniqueprods h2 {
    font-size: 34px !important;
  }
  .header img{
    margin-left: 15px !important;
  }
  .sub.complainforms.whistleforms.fundPrices {
    width: 75% !important;
  }
  .row .col-sm-6.buttonscta .call {
    background-color: #fff !important;
    color: #04a753 !important;
    padding: 12px 20px;
    border-radius: 10px;
    border: 1px solid;
  }
  .row .govdirectors h2 {
    color: #000 !important;
    text-align: center !important;
    /* padding-bottom: 0%; */
  }
  .row .shares {
    background-color: #0fb583;
    padding-top: 78px;
  }
  .col-sm-6.buttonscta {
    font-size: 15px;
  }
  .row .swiper-slide h3 {
    font-size: 20px !important;
    text-align: left;
  }
  .col-sm-4.side .buy {
    font-size: 16px !important;
  }

  .col-sm-6.explore p {
    font-size: 17px;
  }
  .newbtn .blue, .newbtn .gray {
    font-size: 15px;
  }
  div#ntn h2 {
    padding-bottom: 25px !important;
    font-size: 32px !important;
  }
  .col-sm-4.side li {
    font-size: 18px;
    padding-bottom: 15px;
  }
  .col-sm-4.side li img {
    margin-right: 7px;
    width: 22px;
  }
  .col-sm-8.stepforms.step.steps .ant-input-number input {
    box-shadow: 0px 4px 10px 0px rgba(28, 117, 188, 0.16) !important;
    padding: 30px 10px !important;
    border: 1px solid #d9d9d9 !important;
  }
  .row .col-sm-7 p {
    margin-left: 4%;
  }

  .row .govdirectors {
    padding: 90px 0 !important;
  }

  .col-sm-6.buttonscta .buy {
    padding: 4px 6px !important;
  }

  .header .navbar-collapse a.nav-link a {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    padding: 2px;
  }
  .sub.shariahcom p.aos-init.aos-animate {
    font-size: 15px !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    margin-top: 5px;
    margin-left: 0px;
  }
  .desktop nav.bg-body-tertiary {
    margin-left: -5%;
    padding: 0;
  }
  .tabss .col-sm-12.heads.text .col-sm-9.address p, .tabss .col-sm-12.heads.text .col-sm-3.write p {
    font-size: 14px !important;
  }
  .tabss .col-sm-12.heads.text .col-sm-4.cardcta h3, .tabss .col-sm-12.heads.text .col-sm-4.cardcta a {
    font-size: 16px !important;
  }
  .tabss .col-sm-12.heads.text .col-sm-4.writeus {
    padding: 3% !important;
  }
  .tabss .col-sm-12.heads.text .col-sm-4.writeus.indivuuaal, .sub.productetxt.iinndiivuaaal{
    padding: 0 !important;
  }
  .secp-img img {
    vertical-align: middle;
    width: 100% !important;
  }
  .col-sm-6.explore h3 {
    font-size: 26px !important;
  }
  .col-sm-12 .row.prodbox .col-sm-4 {
    margin: 10px 14px;
  }
  .nav-link h4 {
    margin-left: -5px !important;
  }
  .donwloadapps h2 {
    font-size: 34px !important;
  }
  .container.donwloadapps .row {
    padding: 4% 8% !important;
  }
  .newbtn a {
    padding: 10px 20px !important;
  }
  .newbtn .blue, .newbtn .gray{
    font-size: 15px !important;
  }
  .col-sm-6.formstyle.contactFormstyle H3 {
    font-size: 38px;
    line-height: 50px;
  }
  .col-sm-4.side li {
    font-size: 17px !important;
    padding-bottom: 19px !important;
  }
  .col-sm-6.explore p {
    font-size: 15px !important;
  }
  .col-sm-6.formstyle.contactFormstyle input{
    height: 50px;
    padding: 10px !important;
  }
  .col-sm-6.formstyle.contactFormstyle .ant-input-number-input-wrap{
    height: 50px !important;
  }
  .row.contactLinks .col-sm-4.pdfs h3 {
    font-size: 21px !important;
  }
  .row.contactLinks .col-sm-4.pdfs a {
    font-size: 17px;
  }
  .row.contactLinks svg {
    height: 80px !important;
    width: auto !important;
    margin-bottom: 10px;
  }
  .col-sm-4.vidostak h4 {
    font-size: 16px !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-left: -15px;
  }
  .row.addsa.featureRow .col-sm-3 p{
    width: 100%;
  }
  .row.mySuppliment.sumpleRider{
    padding: 20px;
  }
  .nav-link.active h4 {
    font-size: 24px !important;
  }
  .row .col-sm-6.formstyle.rightform {
    padding: 2.8%;
  }
  .container-fluid.header {
    padding: 15px 0;
  }
  .patern li {
    width: 45% !important;
  }
  a.nav-link {
    padding-top: 2px;
  }
  .ant-steps-item .ant-steps-item-icon {
    margin-right: 15px !important;
  }
  .ant-steps .ant-steps-item-title::after {
    left: -17% !important;
  }
  .mynutonsstep button {
    margin-top: 7% !important;
  }
  .react-reveal.col-sm-12.termsSection p {
    width: 100% !important;
  }
  .col-sm-12.heads.text.mymaxheight.bot .nav-link.active h4 {
    font-size: 24px !important;
  }
  .col-sm-8.stepforms.step.steps .ratingSection button.ant-btn span {
    font-size: 13px !important;
  }
  .container-fluid.shares.marbot .col-sm-7.audp h2 {
    text-align: left !important;
    color: #fff;
  }

  .container-fluid.shares.marbot.mufti .row .col-sm-12 h2 {
    width: 100% !important;
  }
  .dropdown-menu.show .individualDropdown.nav-item.show.dropdown .dropdown-menu.show{
    left: -210px !important;
  }
  .dropdown-menu.show .GroupDropdown.nav-item.show.dropdown .dropdown-menu.show{
    left: -280px !important;
  }
  .dropdown-menu.show .policyDropdown.nav-item.show.dropdown .dropdown-menu.show{
    left: -160px !important;
  }

  a.nav-link {
    padding: 0px 10px !important;
  }
}

@media only screen and (max-width: 1370px) {
  .row.mySuppliment p {
    margin-left: 0 !important;
  }
  .col-sm-6.formstyle.contactFormstyle H3 {
    font-size: 32px !important;
    line-height: normal !important;
}
  /* .nav-item.dropdown {
    padding: 0 10px !important;
  } */
  .col-sm-12.heads.text.mymaxheight.bot .nav-link.active img, .col-sm-12.heads.text.mymaxheight.bot .nav-link.active h4, .col-sm-12.heads.text.mymaxheight.bot .nav-link img{
    margin: 0 !important;
  }
  .col-sm-12.heads.text.mymaxheight.bot .nav-link h4{
    margin: -20px 0 0 !important;
  }
  .userSidebar {
    max-height: 83vh !important;
  }
  .row.mySuppliment.DIFFERENTIATORS{
    padding: 10px !important;
  }
  .row.newRow {
    align-items: flex-start !important;
  }
  .riders .suplimentsicon .supliment .row.mySuppliment.DIFFERENTIATORS img.sssuupplemnet {
    width: 90px !important;
    height: 90px !important;
  }
  .tabss .col-sm-12.heads.text p {
    font-size: 14px !important;
  }
  .f {
    position: absolute;
    bottom: 3%;
    /* left: 11%; */
    padding: 0 5px I !important;
  }
  .row.mySuppliment {
    width: 100% !important;
  }
  .col-sm-4.side h3 {
    font-size: 30px;
    color: #0e76bc;
  }
  .statement.row h6 {
    font-size: 15px !important;
  }
  .ant-steps-item.ant-steps-item-process.ant-steps-item-active
    .ant-steps-item-title {
    color: #fff !important;
    font-size: 19px !important;
    font-weight: 600;
  }
  .ant-steps-item-title {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500;
  }
  .nav-item.dropdown a {
    font-size: 14px !important;
  }
  .ant-steps .ant-steps-item-title::after {
    left: -20% !important;
  }
  .col-sm-6.buttonscta .buy {
    padding: -1px 11px !important;
  }
  li, span, p, td {
    font-size: 15px !important;
  }
  .col-sm-6.buttonscta {
    width: 30% !important;
  }
  .row.greenbox p {
    font-size: 30px !important;
    line-height: 55px !important;
  }
  .row.greenbox a {
    font-size: 28px !important;
    line-height: 55px !important;
  }
  .row.greenbox h4 {
    font-size: 18px !important;
    font-weight: 400;
  }
  .react-reveal .audit h3 {
    color: #000;
    font-size: 30px !important;
  }
  .row.statment h2 {
    font-size: 30px !important;
  }
  .statment button.accordion-button {
    font-size: 22px !important;
  }
  .button-container.app-buttons button{
    margin-top: 0 !important;
    font-size: 15px !important;
  }
  .motor h2 {
    color: #0e76bc;
    font-size: 28px !important;
  }

  .container.tabss.motor .row.tabscss p {
    font-size: 14px !important;
    padding-bottom: 0 !important;
  }

  .col-sm-4.cardcta {
    background: #0e76bc;
    padding: 5.5% 5% !important;
    margin-top: -0.6%;
  }

  .col-sm-4.writeus {
    padding: 0% !important;
  }

  .tabss .col-sm-12.heads.text .col-sm-4.cardcta a {
    font-size: 16px !important;
  }

  .sub.surplus {
    width: 90% !important;
    padding: 60px 0 !important;
  }

  /*changes*/
  .col-sm-4.writeus {
    margin-left: -0.7%;
  }

  .nav-item.dropdown a {
    font-size: 14px !important;
    padding: 3px !important;
  }

  .tabss img {
    width: 97% !important;
  }
  .row .col-sm-12.heads.adds.firstpart {
    padding: 66px 0;
  }
  .col-sm-6.explore h3.salamProdMenu {
    color: #fff;
    font-size: 26px !important;
}
  .row .swiper-slide h3 {
    font-size: 19px !important;
  }
  .row .govdirectors {
    padding: 87px 0 !important;
  }

  .col-sm-6.buttonscta .buy {
    padding: 0px 12px !important;
  }
  .donwloadapps .buy {
    padding: 17px 33px;
  }
  .header .navbar-collapse a.nav-link a {
    font-size: 14px !important;
  }
  .col-sm-12 .whoweare p {
    font-size: 14px !important;
  }
  .container.governancemain.motor .marbot {
    margin-bottom: 2%;
  }
  .container.governancemain.motor .marbot.CAPPITAAAL {
    margin-bottom: 8%;
  }
  div#ntn h2 {
    font-size: 30px !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    margin-top: 0.2%;
  }
  .row.recommendations button.ant-btn span {
    font-size: 10px !important;
  }

  .desktop nav.bg-body-tertiary {
    margin-left: -10%;
    padding: 0;
  }
  .secp-img img, svg {
    vertical-align: middle;
    width: 100% !important;
  }
  .buy {
    margin: 0px 0px;
  }
  .tabss .col-sm-12.heads.text .col-sm-8.shaariaahAdvisorrr h3{
    padding-bottom: 0 !important;
  }
  .col-sm-6.buttonscta .buy {
    padding: 3px 12px !important;
  }

  .row.tabscss img {
    max-height: 550px !important;
    margin: 0 !important;
}
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-left: -5px;
    margin-top: 8%;
  }
  .row .col-sm-6 {
    flex: 0 0 auto;
    width: 49.19%;
  }
  a.nav-link {
    padding-top: 0px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 0px;
  }
  .dropdown-menu.show .nav-link {
    margin-left: 1px;
    margin-top: 11px;
    padding: 3px !important;
  }
  .container.savingssec {
    max-width: 90%;
  }
  input#nest-messages_user_email {
    padding: 10px;
    width: 215px;
  }
  .dashboardTabs .box {
    width: 100%;
  }
  .col-sm-3 .lastBox {
    top: -129px;
  }
  .innerLine {
    width: 100% !important;
  }
  .col-sm-12.shareholding.myntn p {
    margin: 0 auto !important;
  }
  .ratingSection .col-sm-6 .row .col-sm-9 p {
    color: #a39e9e;
    font-size: 13px !important;
    font-weight: 400;
    padding-top: 0px !important;
  }
  .row.tabscss.shhaariahAdvisor{
    align-items: flex-start !important;
  }
  .col-sm-8.stepforms.step.steps .ratingSection button.ant-btn span {
    font-size: 12px !important;
  }
  .container-fluid.shares.marbot .col-sm-7.audp h2 {
    text-align: left !important;
    color: #fff;
  }

  .container-fluid.shares.marbot.mufti .row .col-sm-12 h2 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1280px) {
  .col-sm-6.buttonscta {
    margin-top: 15% !important;
    margin-bottom: -7% !important;
  }
  .motor .col-sm-9.singlenews h2, .motor h2.posthead {
    font-size: 26px !important;
}
  p.noJOb {
    padding: 0 !important;
  }
  .policyPage.container .accordion-button {
    font-size: 18px !important;
    gap: 10px !important;
  }
  .swiper.swiper-initialized.swiper-horizontal.mySwiper.slidesbyvcan {
    width: 85% !important;
  }
  .policyPage.container .accordion-body{
    font-size: 14px !important;
  }
  .myfooter .footerlogo {
    width: 60px;
  }
  .react-reveal.col-sm-3.moreWidth {
    max-width: 35%;
    flex: 0 0 35%;
  }
  .react-reveal.col-sm-3.lessWidth {
    max-width: 20%;
  }
  .illustrator .illustrationImage.col-sm-4 {
    margin-left: -62px;
    margin-top: 106px !important;
    width: 35%;
  }
  .ratingSection .col-sm-6 .row .col-sm-9 p {
    color: #a39e9e;
    font-size: 12px !important;
    font-weight: 400;
    padding-top: 0px !important;
  }
  .lastBox .lastBoxSection {
    padding: 99px 52px;
  }
  input#nest-messages_user_email {
    padding: 10px;
    width: 195px !important;
  }
  .container.homemenu {
    padding: 100px 0;
  }
  .container.productnav a {
    padding: 16px;
  }
  .nav-item.dropdown a {
    font-size: 14px !important;
  }
  .row.greenbox p {
    font-size: 27px !important;
    line-height: 55px !important;
  }
  .row.greenbox a {
    font-size: 25px !important;
    line-height: 55px !important;
  }
  .col-sm-4.cardcta {
    background: #0e76bc;
    padding: 5% 5% !important;
    margin-top: -0.6%;
  }

  .sub.cta .col-sm-4.writeus {
    padding: 0% !important;
    margin-left: 0 !important;
  }

  .col-sm-3.write {
    width: 25%;
    margin: auto;
  }
  .sub.complainforms.whistleforms.fundPrices {
    width: 75%;
}
  .col-sm-9.address {
    width: 100% !important;
  }

  .tabss .col-sm-12.heads.text .col-sm-9.address p {
    text-align: center;
  }

  .tabss .col-sm-12.heads.text p {
    font-size: 26px !important;
  }

  .tabss .col-sm-12.heads.text .col-sm-4.cardcta a {
    font-size: 14px !important;
  }

  .sub.cta .col-sm-4.cardcta {
    margin-top: 0 !important;
  }

  .sub.surplus {
    width: 90% !important;
    padding: 60px 0 !important;
  }

  .row.whoweare.codeofconduct p.aos-init.aos-animate {
    width: 75% !important;
    margin: 20px auto;
  }
  .motor h2.shariahhead {
    font-size: 36px !important;
  }
  p.aos-init.aos-animate {
    font-size: 14px !important;
  }
  .react-reveal.col-sm-6 p, .col-sm-7.audp.aboutContent p {
    font-size: 13.5px !important;
  }
  .motor .container-fluid.shares.marbot .col-sm-7.audp h2{
    font-size: 26px !important;
  }
  .col-sm-12.heads .container-fluid.shares.marbot.mufti h3{
    font-size: 21px !important;
  }
  .row.whoweare.codeofconduct h2 {
    padding: 0 !important;
  }
  .col-sm-12.shareholding {
    padding-top: 0px;
    padding-bottom: -227px;
    text-align: center;
  }
  .container-fluid.header .col-sm-9 {
    max-width: calc(100% - 100px);
    flex: 0 0 100%;
  }
  .container-fluid.header .col-sm-3 {
    max-width: 100px !important;
  }
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-left: -5%;
  }

  .nav-item.dropdown a {
    font-size: 14px !important;
    padding: 2px !important;
  }

  .nav-item.dropdown {
    margin-left: -1%;
  }

  .row .button-container {
    /* display: flex;
        justify-content: center;
        margin-left: -8%;
        margin-top: 7%; */
    font-size: 14px;
  }

  .col-sm-4 .react-card-front button img {
    width: 80%;
  }
  div#directors .col-sm-4 {
    margin-top: 4% !important;
  }
  div#managment .col-sm-4 {
    margin-top: 4%;
  }
  div#directors .col-sm-4 h2 {
    padding: 35px 0;
  }
  div#managment .col-sm-4 h2 {
    padding: 35px 0;
  }
  .row .col-sm-12.shareholding a {
    margin-left: 45%;
    width: 14%;
  }

  .row .swiper-slide h3 {
    font-size: 20px !important;
    text-align: left;
  }

  .col-sm-6.buttonscta {
    font-size: 14px;
  }

  .row .button-container {
    /* margin-left: -4%; */
    font-size: 12px;
  }

  .header .navbar-collapse a.nav-link a {
    margin-left: -12px;
  }

  .desktop nav.bg-body-tertiary {
    margin-left: 0;
  }

  .secp-img img,
  svg {
    vertical-align: middle;
    width: 100% !important;
  }

  .row .col-sm-6.formstyle.rightform {
    padding: 1.6% 4%;
    background: #fff;
    margin-bottom: 26px;
  }

  .row .govdirectors {
    padding: 133px 0 !important;
  }

  .tabss .col-sm-12.heads.text .react-reveal.col-sm-4.cardcta.cardcta a {
    font-size: 16px !important;
  }

  .col-sm-4.cardcta {
    padding: 5% 3% !important;
  }
  .ant-radio-group
    label.ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-in-form-item {
    width: 50px !important;
  }

  .container-fluid.shares.marbot.mufti .row .col-sm-12 h2 {
    width: 100% !important;
  }
  .nav-item.dropdown {
    margin-left: 0% !important;
  }
  .col-sm-12.eligNumber div {
    width: 100%;
  }
  .row.mynewboxes .col-sm-6 {
    height: fit-content;
  }
  .row.mySuppliment .col-sm-12.newnoPad p{
    margin-left: 0 !important;
  }
  .eligNumber h3 {
    padding-bottom: 0px !important;
  }

  .row.mySuppliment p {
    padding: 0 !important;
    margin-left: 20px !important;
  }
  /* .col-sm-12.eligNumber div {
    width: 135px;
    height: 130px;
  } */

  /* .combtn {
    padding: 0 !important;
} */
  #directors .col-sm-4:nth-child(2) {
    width: 100% !important;
  }
  #directors .col-sm-4:nth-child(2) {
    width: 100% !important;
  }
  #directors .col-sm-4:nth-child(2) {
    width: 50% !important;
    text-align: center;
  }
  #directors .col-sm-4:nth-child(1) {
    width: 50% !important;
    text-align: center;
  }
  .col-sm-12.mediaCenterHeading h2 {
    padding: 50px 0 30px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .illustrator {
    height: 420px;
  }
  .header img {
    width: 60px !important;
}
.menusildes.VIDEEEOS {
  padding-bottom: 20px;
}
  .container.governancemain.motor.nnewwBAnnner .col-sm-4.vidostak, .row.tabscss.shhaariahAdvisor .col-sm-4, .row.tabscss.shhaariahAdvisor .col-sm-8.shaariaahAdvisorrr {
    flex: 0 0 auto;
    width: 50%;
    max-width: 50%;
  }
  .header .navbar-collapse a.nav-link a, .dropdown-menu.show .dropdown-menu.show a {
    font-size: 12px !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
  margin-left: 0px;
  font-size: 12px !important;
}
.tabss .col-sm-12.heads.text .mycontents p {
  font-size: 15px !important;
}
.container.homemenu .col-sm-4{
  max-width: 40% ;
  flex: 0 0 40%;
}
.container.homemenu .col-sm-8{
  max-width: 60% ;
  flex: 0 0 60%;
}
  .illustrator .illustrationImage.col-sm-4 {
    margin-left: -60px;
  }
}
