h1 {
  font-family: "Roboto", sans-serif;
}
h2 {
  font-family: "Roboto", sans-serif;
}
h3 {
  font-family: "Roboto", sans-serif;
}
h4 {
  font-family: "Roboto", sans-serif;
}
span {
  font-family: "Roboto", sans-serif;
}
div {
  font-family: "Roboto", sans-serif;
}
a {
  font-family: "Roboto", sans-serif;
}
p {
  font-family: "Roboto", sans-serif;
}
li {
  font-family: "Roboto", sans-serif;
}
button {
  font-family: "Roboto", sans-serif;
}
body {
  background: #fff !important;
}
.row.preloader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}
.row.preloader h2 {
  color: #fff !important;
  font-size: 35px;
  padding-top: 35px;
  font-weight: 800;
  letter-spacing: 5px;
}
.imagepre.col-sm-12 {
  text-align: center;
}
.row.preloader img {
  width: 7%;
}
.col-sm-6 {
  max-width: 50% !important;
}
.row {
  align-items: center !important;
}
svg {
  font-size: 38px !important;
}
.menu {
  text-align: end;
}
.header {
  padding: 15px;
  position: fixed;
  width: 100%;
  background-color: #00000059;
  z-index: 9999999999;
  top: 0;
}
.header img {
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  width: 18%;
  margin-left: 30px;
}
.container.banner {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
  display: flex;
  align-items: end;
  justify-content: center;
  max-width: 100%;
  position: relative;
}
.container.homemenu {
  max-width: 100%;
  padding: 0 40px;
}
.container.banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #0000004b;
}
.container.banner .react-reveal {
  display: inline-flex;
}
ul.articleLinksss{
  padding-top: 20px;
}
.noHeightttt a{
  color: #000;
}
.col-sm-9.singlenews .col-sm-12.heads.text.newsheight.noHeightttt h2{
  font-size: 26px !important;
  padding-bottom: 15px !important;
  padding-top: 5px !important;
  margin-left: 10px;
}
.banner h2.letters {
  text-align: center;
  color: #fff;
  font-size: 62px;
  font-weight: 800;
}
.banner h2 {
  text-align: center;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  position: relative;
  z-index: 99999;
  margin-bottom: 0 !important;
}
.banner h2 span {
  padding-right: 10px;
}
.banner .col-sm-12 {
  text-align: center;
}
.buttons {
  background: #000;
  background-position: 0 0;
  background-size: 200% 200%;
  animation-iteration-count: infinite;
  backface-visibility: hidden;
  animation-timing-function: ease-in-out;
  border: unset;
  border-radius: 20px;
  bottom: 1%;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  z-index: 9999;
  padding: 10px 50px;
  margin-top: 15px;
  text-transform: capitalize;
}
@keyframes buttonAnim {
  0% {
    background-position: 10%;
  }
  50% {
    background-position: 150%;
  }
  100% {
    background-position: 10%;
  }
}
.container.homemenu {
  height: 100%;
  background: url("../Assets/white.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  padding: 200px 0;
}
.container.homemenu h2 {
  font-size: 52px;
  color: #fff;
  letter-spacing: 7px;
  padding: 50px 0;
  text-align: center;
}
/* .col-sm-4 {
    width: 25% !important; 
} */
.col-sm-8 {
  width: 75% !important;
}
.explore .react-reveal {
  display: inline-flex;
}
.menus img {
  width: 100%;
}
.menusildes {
  text-align: center;
}
.menusildes h3 {
  color: #fff;
  background: #0c551d;
  margin-top: -43px !important;
  z-index: 9999999;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  position: relative;
  width: fit-content;
  margin: auto;
  display: flex;
  justify-content: center;
  /* padding-top: 20px; */
  font-size: 22px !important;
  /* padding-bottom: 20px; */
  border-radius: 15px;
  padding: 15px 20px;
}
.side h3 {
  color: #fff;
  font-weight: 700;
}
a {
  text-decoration: nones;
}
.heads {
  padding-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 99;
}
.heads.text {
  justify-content: flex-start;
  padding: 0 0px;
}
.col-sm-12.button h3 {
  font-size: 14px;
  font-weight: 500;
}
.heads h2 {
  color: #fff;
  font-size: 46px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
}
.heads h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
.heads p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}
.aboutmenu {
  animation: buttonAnim 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  /* background-image: url(../Assets/menubg.png)!important; */
  background-position: 50%;
  background-position: 0 0;
  background-size: 110% 110%;
  height: 100vh;
}
p.aos-init.aos-animate {
  width: 85%;
  margin: 20px auto;
}
.col-sm-12.button h3 {
  background: radial-gradient(circle, #01a79e 30%, #1c74bc 80%);
  padding: 10px;
  width: 15%;
  text-align: center;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 25px !important;
  margin: auto;
  border-radius: 15px;
  background-position: 0 0;
  background-size: 200% 200%;
  animation: buttonAnim 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.swiper-pagination {
  display: none;
}
.newsevents .swiper-pagination {
  display: block !important;
}
a {
  text-decoration: none !important;
}
.faderight {
  color: #fff;
  animation: fadeInRight 1s ease-in-out;
}
/* text sleek animation fade effect */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(200px);
  }
  to {
    opacity: 1;
  }
}
.fadeleft {
  color: #fff;
  animation: fadeInLeft 1s ease-in-out;
}
/* text sleek animation fade effect */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
  }
}
.col-sm-6.buy a {
  padding: 10px 20px;
  font-size: 14px;
  width: 12%;
  text-align: center;
  display: block;
  margin-left: auto;
}
.col-sm-6.buy {
  text-align: right;
}
.col-sm-6.call a {
  padding: 10px 20px;
  font-size: 14px;
  display: block;
  width: 20%;
  text-align: center;
}
.container.footer {
  max-width: 100%;
}
.col-sm-6.call {
  text-align: left;
}
.footer {
  padding-bottom: 10px;
  background-color: transparent !important;
  position: fixed;
  bottom: 0;
  z-index: 9999999;
  width: 100%;
}
.col-sm-12.homepagesec.buynowpage {
  background-color: #0fb583;
  padding: 10%;
}
div#ntn h2 {
  text-align: center;
  padding-bottom: 40px;
}
.ant-select-selector {
  height: 50px !important;
  padding: 10px 10px !important;
  margin-bottom: 10px !important;
}
.ant-picker {
  width: 100% !important;
  height: 50px;
  padding: 10px !important;
  padding-top: 20px !important;
  padding-left: 0 !important;
}
.formSubmit {
  display: flex;
}
.formSubmit .react-reveal {
  width: 50%;
}
.formSubmit .react-reveal button {
  width: 90%;
}
.form .ant-form-item {
  margin: 5px 0;
}
.form .ant-form-item input {
  padding: 15px 15px !important;
  border-radius: 5px !important;
}
.Buynowform button {
  background: #000 !important;
  height: 60px !important;
}
.tabss .form button:hover {
  background-color: #0e76bc !important;
}
.Buynowform h4.oswald {
  padding-bottom: 30px;
  color: #0e76bc !important;
}
.slidesbyvcan .swiper-button-next::after, .slidesbyvcan .swiper-button-prev::after{
  background: #0fb583 !important;
  border: unset !important;
}
.react-reveal.container.tabss.motor.buynow {
  background-color: #0fb583;
  padding: 10% !important;
  margin-top: 0 !important;
}
.buynowpage input {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 10px;
  border-radius: 15px;
  border: unset;
}
.buynowpage h4 {
  font-size: 42px;
  text-transform: capitalize;
}
.buynowpage button {
  margin-right: 10px;
}
.formSubmit {
  text-align: center;
}
.container.faderight.menuside {
  height: 100%;
  padding: 130px 20px 50px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
p::-webkit-scrollbar {
  width: 0px !important;
}
body {
  overflow-x: hidden !important;
}
.accordion.accordion-flush {
  width: 40%;
  margin: auto;
}
button.accordion-button.collapsed {
  background: transparent !important;
  color: #000 !important;
  margin-bottom: 10px;
}
.row.claimprocedure h2.accordion-header {
  text-align: left !important;
  padding-bottom: 0;
}
button.accordion-button {
  background: transparent !important;
  padding: 20px;
  width: 100%;
  text-align: center;
  color: #000 !important;
  font-weight: 900;
  text-transform: capitalize;
}
button.accordion-button {
  background: #043627 !important;
  color: #fff !important;
}
.accordion {
  width: 50%;
}
.accordion-item {
  border: unset;
  border-bottom: 1px solid #efefef;
}
.accordion-body {
  background: #043627 !important;
  color: #fff !important;
}
.accordion-item {
  background-color: transparent !important;
}
.container.submenus {
  padding: 0;
}
.container.submenus button {
  margin: 0 !important;
  color: #fff !important;
}
.container.menuBar button {
  color: #fff !important;
}
.container.menuBar .accordion-button::after {
  filter: invert(1) !important;
}
.container.menuBar button.accordion-button {
  background: #0c551d !important;
}
.container.menuBar .accordion-body {
  background: #0c551d !important;
  padding: 0px;
  margin-bottom: 10px;
}
.backbtn {
  text-align: center;
}
button:focus {
  box-shadow: unset !important;
  border: unset !important;
}
.accordion-body ul li {
  list-style: none;
  color: #fff;
  padding: 10px 5px;
}
.accordion-body ul li a {
  list-style: none;
  color: #fff;
}
.accordion-body ul {
  margin-bottom: 0;
}
.react-reveal {
  position: relative;
  z-index: 999;
}
.desktop {
  display: block !important;
}
.mobile {
  display: none !important;
}
.dropdown-menu.show .dropdown-menu.show {
  left: 100%;
  top: 0%;
}
.desktop nav.bg-body-tertiary {
  background-color: transparent !important;
}
.nav-item.dropdown a {
  color: #fff;
  font-weight: 500;
  font-size: 15px;
}
.dropdown-menu.show {
  background: #0e76bc !important;
}
.dropdown-menu.show a {
  color: #fff;
  font-weight: 500;
  font-size: 14px !important;
  padding: 10px;
}
.dropdown-menu.show .nav-link.show {
  color: #fff !important;
  background: #0c551d !important;
}
.nav-link.show {
  color: #fff !important;
}
.dropdown-menu.show a:hover {
  background: #0c551d !important;
}
.navbar-expand-lg .navbar-nav {
  flex-direction: row;
  width: 100%;
  justify-content: end;
}
.nav-item.dropdown {
  padding: 0 10px;
}
.dropdown-menu.show {
  padding: 0;
}
.dropdown-menu.show .nav-item.dropdown {
  padding: 0px !important;
}
.container-fluid.header .row {
  width: 100%;
  margin: auto;
}
.heads h3.aos-init.aos-animate {
  width: 85%;
  text-align: left;
  margin: 0 auto;
  font-size: 26px;
}
.sub {
  padding-top: 20px;
}
.col-sm-12.heads.adds h2 {
  width: 85%;
  margin: 0 auto;
  text-align: left;
  font-size: 48px;
}
.sub ul {
  width: 100%;
  margin: auto;
}
.sub ul li {
  padding-bottom: 10px;
}
.sub table {
  width: 50%;
  margin: auto;
}
.sub th {
  padding: 10px;
  color: #000;
  font-weight: 900;
}
.sub td, .sub td a {
  padding: 10px;
  color: #000;
}
.sub tr:hover td a{
  color: #fff;
}
.container.faderight.prodd:before {
  content: "";
  background: #00000078 !important;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh !important;
  width: 100%;
  background-size: cover !important;
  z-index: 9999999;
  background-position: center;
}
.container-fluid.faderight.prodd:before {
  content: "";
  background: #00000078 !important;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh !important;
  width: 100%;
  background-size: cover !important;
  z-index: 9999999;
  background-position: center;
}
.container-fluid.faderight.prodd {
  padding: 0;
}
.container-fluid.about.news {
  background-position: center;
  background-size: contain;
  background-attachment: fixed;
}
h1,
h2,
h3,
h4 {
  position: relative;
  z-index: 9999;
}
.col-sm-12.button {
  position: relative;
  z-index: 99999999;
}
.uniq {
  padding-bottom: 20px;
  text-align: left !important;
  width: 100%;
  margin: 0 auto;
}
.heads.adds.myminheight {
  height: 50vh !important;
}
.heads p {
  color: #000;
  font-size: 20px;
  font-weight: 400;
}
.heads li {
  color: #000;
  font-size: 18px;
  font-weight: 400;
}
.cor li {
  list-style: none;
}
.cor ul {
  padding-left: 0;
  padding-bottom: 20px;
  padding-top: 15px;
}
.col-sm-12.heads.text.mymaxheight {
  height: 100%;
  /* padding-bottom: 50px; */
}
.col-sm-12.heads.text.mymaxheight h2 {
  text-align: left;
  width: 85%;
  margin: auto;
}
.col-sm-8.menus.news img {
  height: 450px;
  object-fit: cover;
}
.container.homemenu .col-sm-8.menus {
  padding-right: 30px;
}
.col-sm-8.menus.news h3 {
  font-size: 20px;
}
.date {
  text-align: center;
}
.news .col-sm-12.heads img {
  width: 100%;
  margin: auto;
  margin-left: 0;
}
.news .col-sm-12.heads img.BigImage{
  width: 40%;
  margin-left: 30px;
}
.individuals .col-sm-12.button .react-reveal {
  width: 30%;
  display: inline-block;
}
.individuals h3 {
  width: 95% !important;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
}
.individuals .col-sm-12.button {
  text-align: center;
}
.tabss ul.mb-3.nav.nav-tabs {
  border: unset;
  justify-content: center;
}
.tabss img {
  width: 100% !important;
}
/* .tabss li.nav-item {
    background: #000;
    margin: 0 10px;
    margin-bottom: 40px !important;
    width: 30%;
    height: 200px;
    border-radius: 12px;
    justify-content: center;
    display: flex;
} */
 .tabss li.nav-item {
  background: #000;
  margin: 0 10px;
  margin-bottom: 40px !important;
  width: 14%;
  height: 80px;
  border-radius: 12px;
  justify-content: center;
  display: flex;
  margin-bottom: 0 !important;
}
.tabss button {
  color: #fff !important;
  border: unset !important;
  width: 100%;
  border-radius: 12px;
  background: radial-gradient(circle, #231f20 30%, #000 80%);
}
.tabss button:hover {
  border: unset !important;
}
.nav-link.active {
  background: unset !important;
}
.nav-link.active img {
  height: 500px;
  object-fit: cover;
  transition: all 800ms ease-in-out;
  filter: grayscale(0);
  width: 100%;
}
.nav-link img {
  height: 350px;
  object-fit: cover;
  transition: all 800ms ease-in-out;
  filter: grayscale(1);
  width: 100%;
}
.nav-link.active h4 {
  background: #0c551d;
  width: 100%;
  margin: auto;
  font-size: 28px !important;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 0px;
  margin-top: 0px;
  transition: all 800ms ease-in-out;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.col-sm-4.side {
  width: 25%;
}
.tabss .col-sm-12.heads.text .sub.productetxt .col-sm-8 p {

  color: #000 !important;
}
.menusildes.VIDEEEOS img {
  height: 600px;
  object-fit: cover;
}
.nav-link h4 {
  background: #0c551d;
  /* width: 60%; */
  width: 100%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin: auto;
  font-size: 18px !important;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin-top: -20px;
  transition: all 800ms ease-in-out;
}
.mycontents p {
  width: 100% !important;
}
.mycontents h2 {
  text-align: left !important;
}
.list-detail h3.aos-init.aos-animate {
  text-align: left !important;
  width: 100% !important;
  padding-bottom: 20px;
}
.tab-content .row.tabs {
  padding: 100px 50px;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: -100px;
}
.tab-content .row.tabs:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background: #0000007a;}
  .active .naavBox{
    background: #0fb583 !important;
    color: #fff !important;
    transition: all ease-in-out 300ms delay;
  }
  .active .naavBox p{
    color: #fff !important;
    transition: all ease-in-out 300ms delay;
  }
  .naavBox{
    border: #0fb583 1px solid !important;
    margin: 0px 0 50px;
    color: #0fb583;
    padding: 0 20px;
    text-align: center;
    border-radius: 12px;
    transition: all 300ms ease-in-out;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .naavBox p{
    color: #0fb583 !important;
    text-align: center;
    font-weight: 800 !important;
    margin-bottom: 10px;
    font-size: 14px;
  }
.swiper {
  z-index: 99999999 !important;
  display: block;
}
.row.tabs .col-sm-4 {
  position: relative;
  z-index: 9999999;
}
.col-sm-12.heads.text .col-sm-4.why {
  width: 30% !important;
  background: #0c551d;
  margin: 10px;
  height: auto;
  padding: 0 20px !important;
  text-align: center;
  border-radius: 15px;
  transition: transform 0.2s;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.col-sm-4.why:hover {
  background: #0c551d;
  transition: transform 0.9s;
  cursor: pointer;
  transform: scale(1.05);
}
.col-sm-12.heads.text .col-sm-6.why {
  width: 48% !important;
  background: #0c551d;
  margin: 10px;
  height: 400px;
  padding-top: 60px !important;
  padding: 0 20px;
  text-align: center;
  border-radius: 15px;
  transition: transform 0.2s;
}
.tabss .col-sm-12.heads.text {
  height: 100% !important;
}
.tabss .col-sm-12.heads.text h3 {
  font-size: 26px !important;
  padding-bottom: 30px;
  font-weight: 700 !important;
}
.tabss .col-sm-12.heads.text p {
  font-size: 16px !important;
  /* height: 150px; */
  padding-bottom: 0 !important;
  overflow-y: scroll;
  color: #ffff;
  font-weight: 700;
}
.swiper-slide h3 {
  font-size: 24px !important;
  text-align: left;
}
.swiper-slide p {
  width: 100% !important;
}
.coverages .accordion.accordion-flush {
  width: 95%;
  margin: auto;
  margin-top: 50px !important;
  margin-left: 0 !important;
  margin-bottom: 100px !important;
}
.coverages button.accordion-button {
  margin: 0 !important;
  border: unset !important;
  font-size: 28px !important;
}
.cemter {
  text-align: center;
}
.chose {
  padding-bottom: 30px;
}
.stepsslide {
  padding: 50px 0;
}
.stepsslide h3 {
  text-align: left !important;
  font-size: 16px !important;
}
.steps {
  background: #0c551d;
  padding: 24px;
  border-radius: 10px;
  cursor: pointer;
}
.tabss .col-sm-12.heads.text .steps h3 {
  text-decoration: unset !important;
  font-size: 24px !important;
}
.planned {
  height: 250px;
}
.emergency {
  height: 170px;
}
.col-sm-12.heads.text.stock {
  height: 50vh;
}
.list-detail p {
  margin-left: 0 !important;
}
.view {
  text-align: center;
  margin-top: 60px !important;
}
.menuss .accordion.accordion-flush {
  width: 60% !important;
  margin: auto;
}
.takafultable th:first-child {
  font-size: 38px;
  width: 350px;
}
.takafultable th {
  font-size: 38px;
}
.takafultable td {
  padding-bottom: 20px;
  border-bottom: 1px solid #efefef;
  padding-top: 20px;
  font-size: 20px;
}
.marinetext p {
  padding-bottom: 120px;
}
.stepsslide .swiper-pagination {
  display: block !important;
}
.swiper-pagination-bullet {
  background: #0e76bc !important;
}
.swiper-pagination-bullet-active {
  background: #0c551d !important;
}
.swiper-pagination-bullet {
  width: 13px !important;
  height: 13px !important;
}
h2.oswald {
  font-family: "Oswald", sans-serif !important;
}
li {
  list-style: none;
}
ul li {
  position: relative;
  padding-left: 0.8em;
}
ul {
  padding-left: 15px !important;
}
li.nav-item:before {
  content: unset !important;
}
li.nav-item {
  padding-left: 0 !important;
}
.sub img {
  width: 100%;
}
/* new css */
.container.banner .col-sm-12 {
  background: #000000b0;
  padding: 40px;
  margin-left: 3%;
}
.container.banner .col-sm-6 .react-reveal {
  padding-right: 10px;
}
.container.banner h2 {
  text-align: center;
  color: #0c551d;
  font-size: 58px;
}
.container.banner .react-reveal {
  padding-right: 10px !important;
}
.container.banner h2 span {
  color: #1c75bc;
}
.col-sm-6.buttonscta {
  background: transparent !important;
  margin: 0 !important;
  width: 20%;
  text-align: end;
  justify-content: end;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999999999999;
}
.col-sm-6.buttonscta .call {
  background-color: #fff !important;
  color: #0c551d !important;
  padding: 12px 30px;
  border-radius: 10px;
  border: 1px solid;
}
.col-sm-6.buttonscta .buy {
  background-color: #0c551d !important;
  color: #fff !important;
  padding: 12px 30px;
  border-radius: 10px;
  border: 1px solid;
}
.col-sm-4.side h3 {
  color: #0e76bc;
  font-size: 28px;
}
.savingssec video {
  min-height: 550px !important;
  width: 100% !important;
  object-fit: contain;
}
.col-sm-4.side li {
  color: #000;
  font-size: 20px;
  padding-bottom: 21px;
  padding-left: 0 !important;
  font-weight: 500;
}
.col-sm-4.side li img {
  margin-right: 5px;
}
.col-sm-4.side ul {
  margin: 0 !important;
  padding-left: 0 !important;
  padding-top: 15px;
  padding-bottom: 15px;
}
.col-sm-4.side .buy {
  background-color: #0c551d !important;
  color: #fff !important;
  padding: 15px 40px;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 500;
  width: 40%;
  text-align: center;
}
.productmenu .buy {
  background-color: #0c551d !important;
  color: #fff !important;
  padding: 15px 40px;
  border-radius: 10px;
  width: 40%;
  text-align: center;
}
.col-sm-4.side {
  padding-left: 90px;
}
.container.homemenu .col-sm-4.side {
  padding-left: 70px;
}
.productmenu h2 {
  font-size: 48px !important;
  color: #fff;
  text-align: center;
  padding-bottom: 100px !important;
}
.productsslides {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
}
.productsslides img {
  padding-bottom: 50px;
}
.productsslides h3 {
  font-size: 24px;
  color: #092854 !important;
}
.productsslides p {
  font-size: 14px;
  color: #092854;
  opacity: 0.7;
}
.productsslides p.categories {
  font-size: 14px;
  color: #0fb583;
  opacity: 1;
  font-weight: 600;
  padding-top: 40px;
}
.container.productmenu.homemenu {
  background: url("../Assets/green.png") !important;
  background-size: cover !important;
}
.productmenu .row {
  justify-content: center;
}
.newsevents .row {
  justify-content: center;
}
.newsevents img {
  width: 100%;
}
.newsevents h4 {
  color: #000;
  padding-top: 20px;
  text-align: left;
  font-size: 20px !important;
  font-weight: 900;
}
.newsevents p {
  color: #000;
  padding-top: 15px;
  text-align: left;
  opacity: 0.5 !important;
  font-size: 15px !important;
}
.newsevents .swiper-wrapper {
  padding-bottom: 5%;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 18px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  /* background: #0C551D; */
  padding: 12px;
  /* filter: invert(1); */
  border: 1px solid #0c551d !important;
  background-color: #0c551d;
  border-radius: 8px !important;
  color: #fff !important;
  color: #0c551d;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 8% !important;
  left: auto !important;
  top: 80% !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: auto !important;
  right: 12% !important;
  top: 80% !important;
}
.donwloadapps .buy {
  background-color: #0c551d !important;
  color: #fff !important;
  padding: 15px 40px;
  border-radius: 10px;
  width: 40%;
  text-align: center;
}
.donwloadapps h2 {
  font-size: 48px;
  color: #0e76bc !important;
  padding-bottom: 10px;
}
.donwloadapps p {
  font-size: 16px;
  color: #000;
  padding-bottom: 10px;
  opacity: 0.4;
  width: 80%;
}
.donwloadapps ul {
  padding-left: 0 !important;
}
.donwloadapps ul img {
  margin-right: 10px;
  position: absolute;
  left: 0;
}
.donwloadapps ul li {
  padding-left: 35px !important;
  padding-bottom: 15px !important;
  display: flex;
  font-weight: 600;
  align-items: center;
}
.myfooter .footerlogo {
  width: 25%;
  padding-bottom: 45px;
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.container.myfooter {
  max-width: 100%;
  background: #0e76bc;
  padding: 5% 10% 2%;
  border-bottom: 1px solid #fff;
}
.myfooter p {
  color: #ffffff7d !important;
  width: 80%;
}
.myfooter a {
  color: #fff !important;
  text-decoration: unset;
  display: block;
  font-weight: 600;
}
.myfooter li {
  padding-left: 0 !important;
  padding-bottom: 15px;
}
.myfooter ul {
  padding-left: 0 !important;
}
.myfooter h3 {
  color: #fff !important;
  padding-bottom: 15px;
}
.myfooter form#nest-messages {
  display: flex;
}
input#nest-messages_user_email {
  padding: 10px;
  width: 275px;
}
.container.myfooter form button.ant-btn-primary {
  height: 45px;
  margin-left: -10px;
}
.row.prodbox {
  justify-content: center;
}
.container.tabss.motor.pastprices {
  padding: 0;
}
/* .col-sm-3.last {
    margin-top: -20px;
} */
.container.myfooter.hidetab .row {
  align-items: flex-start !important;
}
.myfooter h3 {
  color: #fff !important;
  padding-bottom: 15px;
  padding-top: 35px;
}
.container.donwloadapps .col-sm-6 img {
  width: 100%;
  margin-top: -30%;
}
.container.donwloadapps ul img {
  width: 30px !important;
  margin-top: 0 !important;
}
.container.productss {
  background: #fff;
  padding: 250px 0;
}
.container.corporate.productss {
  background: #043627;
}
.container.corporate.productss h3 {
  color: #fff;
}
.container.corporate.productss .menusildes h3 {
  background: #000;
}
.productnav {
  padding-top: 200px;
}
.container.productnav a {
  border: 1px solid #0c551d;
  color: #0c551d;
  padding: 0 20px;
  display: block;
  text-align: center;
  border-radius: 12px;
  font-weight: 800;
  margin-bottom: 10px;
  transition: all 300ms ease-in-out;
  font-size: 14px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container.productnav .row {
  justify-content: center;
}
.container.productnav a:focus {
  background-color: #0c551d;
  transition: all 300ms ease-in-out;
}
.motor {
  padding-top: 80px;
}
.motor h2 {
  text-align: center;
  color: #0e76bc;
  font-size: 48px;
}
.motor p {
  font-size: 18px;
  padding-bottom: 7%;
  width: 75%;
  margin: auto;
  color: #000;
  padding-top: 15px;
}
.col-sm-8.shaariaahAdvisorrr h3, .col-sm-8.shaariaahAdvisorrr p{
  color: #000 !important;
}
.motor p.pb {
  padding-bottom: 0% !important;
}
.container.productnav {
  max-width: 1320px;
}
.container.tabss.motor {
  width: 100%;
}
.tab-content p {
  color: #000;
  width: 75% !important;
  margin-left: 0 !important;
}
.tab-content li {
  color: #000;
  padding-left: 0;
  list-style: unset !important;
  padding-bottom: 10px;
  padding-left: 10px;
}
.tab-content h3 {
  color: #000;
}
.tab-content ul {
  padding-left: 0 !important;
}
.tab-content .row {
  align-items: flex-start !important;
}
.motor .swiper-button-prev {
  top: 95% !important;
  right: 3% !important;
}
.motor .swiper-button-next {
  top: 95% !important;
  right: 1% !important;
}
.row.whychosesalaam {
  width: 80%;
  margin: auto;
  padding: 120px 0;
  justify-content: center;
}
.chose h2 {
  text-transform: capitalize !important;
}
.row.claimprocedure {
  padding: 10% 5%;
  /* background-image: url(../Assets/additionalfeatures.png); */
  /* background-image: url("https://www.shutterstock.com/shutterstock/photos/2227909939/display_1500/stock-vector-abstract-d-gold-curved-green-ribbon-on-dark-green-background-with-lighting-effect-and-sparkle-with-2227909939.jpg"); */
  background-repeat: no-repeat;
  background-image: url(../Assets/additional.jpg);
  background-size: cover;
}
.col-sm-12 .react-reveal.row.claimprocedure h2 {
  text-align: left !important;
  padding-bottom: 15px;
  color: #fff !important
}
.claimprocedure img {
  width: auto !important;
  margin-right: 10px;
}
.claimprocedure ul {
  padding-left: 0 !important;
}
.claimprocedure li {
  padding-left: 0 !important;
  padding: 1%;
  color: #fff !important;
}
.why p {
  margin: 0 !important;
  width: 100%;
  color: #fff;
}
.motor h2.claimss {
  color: #000 !important;
  padding-bottom: 60px;
  text-align: center !important;
}
.col-sm-12.padtop {
  padding-top: 7%;
}
.row.claimprocedure {
  align-items: flex-start !important;
}
.Active {
  background-color: #0c551d !important;
  color: #fff !important;
}
.container.myfooter form button.ant-btn-primary {
  background-color: #0c551d;
}
.container.donwloadapps {
  background: url("../Assets/white.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  max-width: 100%;
}
.container.donwloadapps .row {
  padding: 4% 12%;
}
.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  padding: 0 !important;
}
.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.col-sm-12.explore.news h2 {
  color: #000 !important;
}
.bggreen {
  background-color: #043627;
  padding: 100px 0;
}
table li img {
  width: 5% !important;
  margin-right: 10px;
}
table li {
  color: #fff !important;
  padding-left: 0 !important;
  padding-bottom: 10px;
}
table ul {
  color: #fff !important;
  padding-left: 0 !important;
  padding-top: 15px;
}
.none {
  display: none !important;
}
.claimprocedure h2 {
  color: #000;
}
.motor .grop p {
  padding-bottom: 10px !important;
  text-align: center;
}
.gropss h2 {
  color: #0e76bc !important;
}
.claimprocedure h2 {
  font-size: 48px;
  font-weight: 600;
}
.grophowtoclaim h2.claimss {
  text-align: center !important;
  padding: 5% 0;
}
.col-sm-12.subheading h2 {
  font-size: 26px;
}
.steps.emergency p {
  color: #fff;
}
.steps.emergency.planned {
  height: 300px;
}
.steps.emergency.planned p {
  font-size: 17px;
}
.govbanner {
  background-image: url("../Assets/gvbanner.jfif");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 350px;
}
.govbanner h2, .govbanner1 h2 {
  color: #fff !important;
}
.governancemain {
  padding-top: 0 !important;
}
.react-card-front button {
  background: transparent;
  border: unset;
}
.react-card-front button img {
  width: 100%;
}
.backbox {
  background-color: #0fb583;
  border: unset;
}
.backbox p {
  color: #fff;
  font-size: 15px;
  margin: 0;
  width: 100%;
  text-align: left;
  padding: 20px;
}
h3.dname {
  text-align: left;
  color: #0e76bc;
  font-weight: 700;
  padding-top: 10px;
  padding-left: 20px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.claimprocedure li img {
  width: 30px !important;
}
p.designation {
  padding: 0;
  margin: 0;
  text-align: left;
  padding-left: 20px;
  font-weight: 500;
  font-size: 18px;
  color: #4b4b4b;
  text-transform: capitalize;
}
.govdirectors {
  padding: 120px 0 !important;
}
.row.manROOW {
  justify-content: space-evenly !important;
  align-items: flex-start !important;
}
.heads .sub.shariahtabs.moreTabsss li.nav-item {
  border: 1px solid #0c551d;
  /* margin: 0 8%; */
  max-width: 35% !important;
  color: #0fb583 !important;
}
.heads .sub.shariahtabs.moreTabsss li.nav-item button{
  color: #0c551d !important;
}
.sub.shariahtabs.moreTabsss .nav-link.active {
  background: #0c551d !important;
}
.govdirectors button.backbox {
  height: 600px;
  margin-bottom: 30px;
  overflow-y: scroll;
  padding: 10px !important;
}
.govdirectors button.backbox p {
  width: 100% !important;
}
.govdirectors button {
  height: 600px;
  margin-bottom: 30px;
}

.shares img {
  width: 80%;
  margin-bottom: -120px;
}
.shares {
  background: url("../Assets/green.png") !important;
  background-size: cover !important;
  padding: 60px 0;
}
.col-sm-12.heads.adds.firstpart {
  background: url("../Assets/white.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}
.col-sm-12.heads.text.govdirectors {
  background: url("../Assets/white.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}
div#ntn {
  background: url("../Assets/white.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}
.col-sm-12.heads.adds {
  background: url("../Assets/white.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}
.col-sm-12.heads.text.retaakaful {
  background: url("../Assets/white.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  margin: 0 !important;
}
.col-sm-12.heads.text.mymaxheight {
  background: url("../Assets/white.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  margin: 0 !important;
}
.col-sm-12.heads.text.mymaxheight.widraw {
  background: url("../Assets/green.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}
body {
  background: url("../Assets/white.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}
.imageshare {
  text-align: center;
}
.shares h2.aos-init.aos-animate {
  color: #fff;
  text-align: left;
}
.shares p.aos-init.aos-animate {
  padding: 0;
  margin: 0;
  color: #fff;
  padding-bottom: 30px;
  padding-top: 30px;
}
.shares li {
  padding-left: 0 !important;
  padding-bottom: 5px;
  padding-top: 10px;
  color: #fff !important;
}
.shares ul {
  padding-left: 0 !important;
  padding-bottom: 5px;
  padding-top: 10px;
}
.col-sm-12.shareholding {
  padding-bottom: 10px;
  text-align: center;
}
.browchure button {
  width: auto !important;
  padding: 0;
  border: none !important;
}
.col-sm-12.shareholding h2 {
  color: #0e76bc !important;
}
button .combtn {
  width: 100% !important;
  padding: 0 50px;
  height: 45px !important;
}
.row.whoweare button {
  border: none;
  padding: 0;
}
.col-sm-12.shareholding a {
  background: #0fb583;
  color: #fff;
  padding: 12px 20px;
  border-radius: 10px;
  display: block;
  width: 10%;
  margin: auto;
  margin-bottom: 15px;
}
.shareholding p {
  color: #000 !important;
  width: 100% !important;
}
.productnav .col-sm-2 {
  width: 100%;
}
.productnav .swiper-pagination {
  display: block !important;
  margin-bottom: -10px;
}
.productnav .swiper-wrapper {
  padding-bottom: 20px;
}
.subheading .swiper-pagination {
  display: block !important;
  margin-bottom: -10px;
}
.subheading .swiper-wrapper {
  padding-bottom: 40px;
}
.showtab {
  display: none;
}
.container.productnav a {
  font-size: 18px;
}
.whoweare {
  padding-top: 0 !important;
}
.col-sm-6.formstyle.rightform {
  padding: 3.6% 4%;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 6px;
}
.col-sm-12.heads.text.mymaxheight.complainbox.whistle {
  padding: 10% 0;
  margin: 2% 0;
}
/* whistleblowing */
.whistleforms h4 {
  font-size: 20px !important;
  font-weight: 700;
  padding-bottom: 20px;
}
.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #000;
}
label.ant-checkbox-wrapper {
  padding-bottom: 10px;
  padding-top: 10px;
}
.whistleforms label.ant-checkbox-wrapper {
  font-size: 16px;
  font-weight: 700;
  opacity: 0.5;
}
.whistleforms .ant-col.ant-col-8 {
  flex: 0 0 50%;
  max-width: 50%;
}
.whistleforms button.ant-btn.ant-btn-primary {
  background: #000;
  border-radius: 0;
  width: 100%;
  margin: 0 !important;
  height: 55px;
  font-size: 18px;
}
.whistleforms .ant-col.ant-col-8 {
  flex: 0 0 50%;
  max-width: 50%;
}
.whistleforms .formstyle textarea {
  height: 300px !important;
  min-height: 300px !important;
}
.ant-checkbox .ant-checkbox-inner {
  width: 30px;
  height: 30px;
  background-color: #efefef;
  border: 1px solid #d9d9d9;
}
.whoweare p {
  margin: 0 !important;
  padding: 0 !important;
  margin-left: 10% !important;
  padding-bottom: 30px !important;
  text-align: justify;
}
.col-sm-12.heads.adds.firstpart {
  padding: 50px 0 100px;
}
.vision h2 {
  margin-left: 0 !important;
  padding-bottom: 15px;
}
.vision ul {
  margin-left: 0 !important;
}
.vision li {
  list-style: disc;
  padding-bottom: 15px;
  padding-right: 25%;
}
.vision {
  padding-left: 5%;
}
.container-fluid.shares .container {
  max-width: 1500px !important;
}
.retak ul img {
  width: 3% !important;
  margin: 0 10px !important;
}
.retaakaful {
  margin: 120px 0;
  margin-bottom: 20px;
}
.col-sm-12.heads.text.news {
  width: 80%;
  margin: auto;
  padding: 120px 0;
}
.text.news h4 {
  text-align: left !important;
  color: #000 !important;
  font-size: 24px !important;
  font-weight: 600;
  padding-top: 20px;
}
.text.news p {
  text-align: left !important;
  width: 100% !important;
  opacity: 0.5 !important;
  font-size: 16px;
}
.text.news .col-sm-4 {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, .2);
  margin-bottom: 50px !important;
  margin: 15px;
  padding: 10px;
  width: calc(33.333% - 30px);
}
.col-sm-12.heads.text.newsheight {
  padding-top: 0;
}
.text.news img {
  width: 100%;
}
.col-sm-9.singlenews h2 {
  color: #000;
  font-size: 40px;
  padding: 50px 0 20px 30px;
  font-weight: 700;
  text-align: left;
}
.row.ArticlesAndBlogs {
  align-items: flex-start !important;
}
.col-sm-9.singlenews p.date.aos-init.aos-animate {
  margin: 0;
  text-align: left;
  padding-left: 30px;
  padding-bottom: 20px;
}
.newsheight p {
  margin: 0 !important;
  padding-bottom: 20px;
  padding-left: 40px;
  text-align: justify;
  width: 100% !important;
}
.singleblog {
  padding: 120px 0px;
}
.sidenews img {
  width: 100%;
}
.sidenews h4 p{
  color: #000;
  font-size: 16px;
  text-align: left;
  margin: 0;
  padding: 0;
  font-weight: 800;
}
.row.arrticleAndBlogs.fio img{
  max-width: 600px;
}
.row.arrticleAndBlogs.fio .col-sm-12{
  display: flex;
  padding: 50px;
  justify-content: center !important;
}
h2.oswald {
  font-family: Oswald, sans-serif !important;
}
.container.tabss.motor.complaaainttt .sub.shariahcom.moreePadd{
  padding-top: 30px;
}
.container.tabss.motor.complaaainttt{
  padding-top: 0;
}
.sidenews p {
  color: #000;
  font-size: 14px;
  text-align: left;
  margin: 0;
  padding: 0;
  font-weight: 400;
}
.sidenews .row {
  margin-bottom: 30px;
}
.heads.text.news .row.ArticlesAndBlogs{
  justify-content: flex-start !important;
}
h2.posthead {
  font-size: 38px;
  font-weight: 700;
  padding-bottom: 10px;
  padding-left: 0;
  text-align: left;
}
.row.singleblog {
  align-items: flex-start !important;
}
.sidenews {
  background: #efefef;
  margin: 0px 30px;
  padding: 15px;
}
.container.boxes {
  max-width: 85%;
}
.row.greenbox .col-sm-3 {
  background: #0fb583;
  width: 23%;
  margin: 10px;
  text-align: center;
  border-radius: 10px;
  padding: 20px 0;
}
.row.greenbox h4 {
  font-size: 22px;
  font-weight: 400;
}
.row.greenbox p {
  font-size: 48px;
  font-weight: 700;
  padding: 0 !important;
  width: 100% !important;
  color: #fff;
  line-height: 72px;
}
.row.greenbox a {
  font-size: 40px;
  font-weight: 700;
  padding: 0 !important;
  width: 100% !important;
  color: #fff;
  text-transform: capitalize;
  line-height: 72px;
}
.audit h3 {
  color: #000;
  font-size: 38px !important;
  font-weight: 700 !important;
}
.audit p {
  margin-top: 0 !important;
  padding-bottom: 5% !important;
  font-size: 16px !important;
  opacity: 0.7 !important;
}
.ethichs {
  background: #0fb583;
  padding: 120px 0 !important;
}
.row.statment h2 {
  text-align: left;
  color: #fff;
  font-size: 48px;
}
.row.statment p {
  text-align: left;
  color: #fff;
  font-size: 18px;
  width: 100%;
  opacity: 0.7;
}
.statment .accordion {
  width: 100%;
}
.statment button.accordion-button {
  background: #fff !important;
  color: #000 !important;
  font-size: 28px;
}
.statment .accordion-body {
  background: #fff !important;
  color: #000 !important;
}
.statment .accordion-body p {
  padding-bottom: 20px;
  opacity: 1;
  font-weight: 700;
  font-size: 28px;
  color: #0e76bc !important;
}
.statment .accordion-body li {
  color: #000 !important;
  list-style: disc;
  opacity: 0.7;
}
.statment .accordion-button {
  box-shadow: unset !important;
}
.statment .accordion-item {
  border: unset;
}
.statment .accordion-body .row {
  align-items: flex-start !important;
}
.sub.shariahcom {
  width: 60%;
  margin: auto;
  text-align: center;
}
.sub.shariahcom p.aos-init.aos-animate {
  font-size: 18px !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.tabscss .col-sm-8 {
  flex: 0 0 auto;
  width: 66.66666667% !important;
}
.shariahtabs ul.mb-3.nav.nav-tabs.nav-fill {
  width: 60%;
  margin-bottom: 5% !important;
}
.row.tabscss {
  width: 80%;
  margin: auto;
  align-items: center !important;
  margin-bottom: 5% !important;
}
.row.tabscss h2 {
  text-align: left !important;
  width: 100% !important;
}
.row.tabscss h3 {
  text-align: left !important;
  width: 100% !important;
}
.row.tabscss p {
  text-align: left !important;
  width: 100% !important;
}
.row.tabscss img {
  height: 700px;
  object-fit: cover;
}
.fatwa h4 {
  color: #0e76bc;
  padding: 20px 0;
  font-size: 28px;
  font-weight: 800;
  text-transform: capitalize;
}
.tabss .row.tabscss .col-sm-12 .fatwa p {
  padding-top: 0 !important;
  padding-bottom: 15px !important;
}
.tabss .row.tabscss .col-sm-12 .fatwa li {
  font-size: 16px;
}
.tabss .row.tabscss .col-sm-12 .fatwa a {
  color: #000;
}
.sub.shariahtabs .nav-link.active {
  background: #0c551d !important;
}
.heads .sub.shariahtabs li.nav-item {
  color: transparent !important;
}
.heads .sub.shariahtabs button {
  background: transparent !important;
}
.heads .sub.shariahtabs li.nav-item {
  background-color: transparent !important;
  border: 1px solid #0c551d;
  padding-bottom: 0 !important;
  color: #0c551d !important;
}
.heads .sub.shariahtabs li.nav-item button {
  color: #0c551d !important;
}
.heads .sub.shariahtabs li.nav-item button.active {
  color: #fff !important;
}
.complainbox {
  padding: 0;
  background-color: #0fb583;
}
.sub.complainforms {
  width: 80%;
  margin: auto;
  padding: 0;
}
.sub.complainforms img {
  width: 100%;
}
.formstyle h2 {
  font-size: 36px !important;
  width: 100% !important;
  color: #fff !important;
  padding-bottom: 20px;
}
.row.contactLinks {
  background: url("../Assets/green.png") !important;
  margin: 0 !important;
}
.sub.shariahcom.moreePadd{
  padding-bottom: 80px;
  padding-top: 0;
}
.tabss .col-sm-12.heads.text .sub.shariahcom.moreePadd p{
  font-size: 20px !important;

}
.row.contactLinks h2 {
  color: #fff !important;
  margin-top: 50px !important;
}
.row.contactLinks .col-sm-4 h3 {
  color: #fff !important;
  font-size: 30px !important;
  font-weight: 600;
}
.row.contactLinks .col-sm-4 {
  margin: 30px 0;
}
.row.contactLinks svg {
  height: 100px;
  margin-bottom: 20px;
}
.row.contactLinks .col-sm-4 a {
  color: white;
  font-size: 24px;
  font-weight: 500;
}
.formstyle input {
  width: 100% !important;
  padding: 20px 15px !important;
}
.formstyle .ant-input-number {
  width: 100% !important;
  /* padding: 10px 15px !important; */
}
.formstyle textarea {
  height: 200px !important;
}
.formstyle button {
  background: #000 !important;
  border-radius: 6px !important;
  width: 100% !important;
  margin: 0 !important;
  height: 60px !important;
  font-size: 18px !important;
  transition: all 300ms ease-in-out;
}
.formstyle button:hover {
  background-color: #0e76bc !important;
}
.heads.text.mymaxheight.complainbox .formstyle p.message {
  width: 60% !important;
  color: #fff;
  background-color: #0e76bc;
  border-radius: 10px;
  padding: 10px !important;
  text-align: center;
  padding-bottom: 10px !important;
}
.col-sm-4.writeus {
  padding: 6%;
}
.col-sm-3.write p {
  padding-top: 0;
  font-weight: 600;
  text-align: center;
}
.tabss .col-sm-12.heads.text .col-sm-9.address p {
  font-size: 16px !important;
  font-weight: 500;
}
.col-sm-4.cardcta {
  background: #0e76bc;
  padding: 7.5% 8%;
  margin-top: 0%;
}
.tabss .col-sm-12.heads.text .col-sm-4.cardcta h3 {
  font-size: 18px !important;
  text-align: left;
}
.tabss .col-sm-12.heads.text .col-sm-4.cardcta h2 {
  font-size: 32px !important;
  text-align: left;
  color: #fff;
  width: 100%;
}
.tabss .col-sm-12.heads.text .col-sm-4.cardcta p {
  width: 100%;
}
.tabss .col-sm-12.heads.text .col-sm-4.cardcta a {
  font-size: 18px !important;
  text-align: left;
  color: #fff;
  width: 100%;
  display: block;
}
.col-sm-4.ctaimgae {
  padding: 0;
}
.col-sm-12.heads.text.mymaxheight.cta {
  padding-bottom: 0;
}
.sub.cta {
  padding-top: 0;
}
.sub.surplus {
  width: 85%;
  margin: auto;
  padding: 120px 0;
}
.tabss .col-sm-12.heads.text .sub.surplus p {
  width: 100%;
  font-size: 18px !important;
}
.sub.surplus h2 {
  width: 100% !important;
}
.sub.surplus .col-sm-9 {
  padding-right: 10%;
}
.sub.shariahcom.esg h2 {
  text-align: center !important;
  padding-bottom: 25px;
}
.govdirectors button.backbox::-webkit-scrollbar {
  width: 0;
}
.row.tabs .col-sm-6 {
  position: relative;
  z-index: 9999999;
}
.tabss .col-sm-12.heads.text .mycontents p {
  font-size: 18px !important;
}
/* savings css */
.savingssec img {
  width: 100%;
}
.container.savingssec {
  max-width: 85%;
}
.col-sm-6.explore h3 {
  color: #fff;
  font-size: 32px !important;
}
.col-sm-6.explore p {
  color: #fff;
  font-size: 18px;
  width: 80%;
}
.newbtn {
  padding-top: 25px;
}
.newbtn a {
  padding: 12px 30px;
  margin-right: 10px;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
}
.newbtn a:hover {
  transition: all 300ms ease-in-out;
  background-color: #043627;
  color: #fff;
  padding: 12px 35px;
}
.newbtn .blue {
  background-color: #0e76bc;
  color: #fff;
  font-size: 16px;
}
.newbtn .gray {
  background-color: #e0e0e0;
  color: #000;
  font-size: 16px;
}
.col-sm-6.explore {
  padding-left: 35px;
}
.sub.productetxt .col-sm-8 {
  width: 66.66666667% !important;
  padding-left: 5%;
  padding-right: 8%;
}
.sub.productetxt .col-sm-4.writeus {
  padding: 0 !important;
}
.sub.productetxt .col-sm-8 h2 {
  width: 100% !important;
}
.tabss .col-sm-12.heads.text .sub.productetxt .col-sm-8 p {
  width: 100% !important;
  font-size: 18px;
  text-align: justify;
}
.sub.productetxt.uniqueprods {
  background: url("../Assets/gray.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding: 100px 0;
}
.col-sm-12.heads.text.mymaxheight.complainbox {
  background: url("../Assets/green.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}
.col-sm-12.heads.text.mymaxheight.complainbox input {
  margin-bottom: 10px !important;
}
.col-sm-12.heads.text.mymaxheight.complainbox textarea {
  margin: 10px 0 !important;
}
.col-sm-12.heads.text.mymaxheight.complainbox button {
  margin: 10px 0 !important;
  border-radius: 6px !important;
}
.sub.productetxt.uniqueprods h2 {
  text-align: center !important;
}
.sub.complainforms.whistleforms form#nest-messages .row {
  margin: 0 !important;
}
.row.prodbox .col-sm-4 p {
  width: 100% !important;
  margin: 0 !important;
}
.row.prodbox .col-sm-4 h3 {
  padding-bottom: 0 !important;
  transition: all 300ms ease-in-out;
}
.row.prodbox .col-sm-4 {
  text-align: center;
  background: #0c551d;
  padding: 40px;
  border-radius: 20px;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  width: 31%;
  margin: 10px 10px;
}
.row.prodbox .col-sm-4 p {
  width: 100% !important;
  margin: 0 !important;
  color: transparent;
  height: 0;
  opacity: 0;
  transition: all 600ms ease-in-out;
}
.row.prodbox .col-sm-4:hover {
  background-color: #fff !important;
}
.row.prodbox .col-sm-4:hover p {
  width: 100% !important;
  margin: 0 !important;
  color: #000;
  height: 400px !important;
  opacity: 0.7;
  transition: all 600ms ease-in-out;
  cursor: pointer;
}
.row.prodbox .col-sm-4:hover h3 {
  width: 100% !important;
  margin: 0 !important;
  color: #0e76bc;
  height: auto !important;
  opacity: 1;
  transition: all 600ms ease-in-out;
  cursor: pointer;
}
.row.prodbox {
  max-width: 85%;
  margin: auto;
  padding-top: 3%;
}
.react-reveal.row.claimprocedure h2 {
  width: 100% !important;
  text-align: left !important;
  color: #0e76bc !important;
}
.container-fluid.shares .row {
  width: 85%;
  margin: auto;
}
.shares.riders h2 {
  padding: 0 !important;
  width: 100% !important;
  color: #fff !important;
  padding-bottom: 30px !important;
}
.shares.riders h3 {
  padding: 0 !important;
  width: 100% !important;
  color: #fff !important;
  font-size: 24px !important;
  text-align: left !important;
}
.shares.riders p {
  padding: 0 !important;
  width: 100% !important;
  color: #fff !important;
  font-size: 18px !important;
}
.supliment {
  padding-bottom: 5px;
}
.shares.riders {
  margin-bottom: 20%;
}
.shares.riders .react-reveal.col-sm-7 {
  padding-left: 5%;
}
.govdirectors h2 {
  color: #000 !important;
  text-align: center !important;
  padding-bottom: 5%;
}
.patern li {
  width: 35%;
  background: #fff;
  color: #000 !important;
  padding: 10px !important;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.audp p {
  padding: 0 !important;
  color: #ffff;
  margin: initial;
  text-align: justify;
}
.aboutContent h2 {
  text-align: left;
  font-size: 48px;
  margin-left: 110px;
  line-height: 68px;
  color: #fff;
}
.aboutContent h3 {
  text-align: left;
  margin-left: 110px;
  line-height: 20px;
  color: #fff;
}
.marbot {
  margin-bottom: 8%;
}
.row.whoweare h2 {
  padding-left: 20px;
  padding-bottom: 5%;
}
.row.whoweare img {
  width: 100%;
}
.row.whoweare.mission p {
  width: 100% !important;
  margin-left: 0 !important;
}
.row.whoweare.mission h2 {
  padding-left: 0 !important;
}
.col-sm-5.allvisions {
  padding: 0 5%;
}
.row.whoweare.mission .col-sm-7 {
  padding: 0;
}
.core {
  background-color: #cccccc;
  margin-top: 5% !important;
}
.core .col-sm-6 {
  padding-left: 0 !important;
}
.core .vision {
  padding-right: 20%;
}
.container-fluid.shares.marbot.mufti h3 {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}
.container-fluid.shares.marbot.mufti {
  padding-bottom: 50px;
}
.row.whoweare.codeofconduct h2 {
  width: 80%;
  text-align: left;
  margin: auto;
  padding-left: 0;
  padding-bottom: 2%;
  padding-top: 2%;
}
.container.faderight.menuside {
  background: #0e76bc;
}
.myntn h2 {
  text-align: center !important;
}
.myntn p {
  margin-left: 0 !important;
}
.button-container {
  display: flex;
  margin-top: 10%;
}
.buy {
  margin: 0 10px;
}
.header a.nav-link a {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
 a.nav-link {
  display: flex !important;
  align-items: center;
}
.govdirectors .row {
  width: 80%;
  margin: 0 auto;

  justify-content: center !important;

}
div#directors {
  padding-bottom: 0 !important;
}
.row .col-sm-6.buttonscta .buy {
  background-color: #0c551d !important;
  color: #fff !important;
  padding: 3px 12px !important;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid;
}
.dropdown-menu.show {
  background: rgba(0, 0, 0, 0.25) !important;
}
.app-buttons img {
  width: 200px !important;
  margin-top: 2%;
}
.toggleoff {
  /*newsevents hidden through this */
  display: none !important;
}
.productshide {
  /*newsevents hidden through this */
  display: none !important;
}
.tr.ant-table-row.ant-table-row-level-0 {
  background-color: #fff !important;
}
.fundPrices table {
  text-align: center;
  width: 100%;
}
.fundPrices th {
  text-align: center;
}
th.ant-table-cell {
  text-align: center !important;
}
.col-sm-12.heads.text.mymaxheight.complainbox.whistle.fundtable {
  padding: 5% 0;
  margin: 2% 0;
}
.pastPriceForm form {
  margin: 6% 0;
}
.pastPriceForm .ant-picker {
  width: 100% !important;
  height: 50px;
  padding: 10px !important;
  padding-top: 20px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 12px;
  background: transparent;
  border: unset;
}
.container.copyright.mobile{
  display: none !important;
}
.col-sm-12.careerText h6 {
  color: #000 !important;
  padding: 0 6%;
}
span.ant-picker-suffix {
  background: #fff;
  padding: 14px;
  position: absolute;
  z-index: 9999;
  right: 0;
  border-radius: 8px;
}
.govbanner1 {
  background-image: url("https://asset.salaamlifetakaful.com/SavingAssets/Assets/img/lifeatsalaam.jpg");
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
  height: 350px;
}
.careerText{
  text-align: center;
  padding-top: 50px;
}
.careerText h6{
  color: #000;
  font-size: 18px;
}
.careerText p{
  font-size: 16px;
  padding-bottom: 0%;
}
.careerText button{ 
  margin: 30px 0;
  background-color: #0fb583 ;
  color: #fff;
  border: none;
  padding: 20px 40px;
  border-radius: 4px;
}
.lifeCards{
  padding: 40px 0;
  justify-content: center;
}
.lifeCards h2{
  font-size: 42px;
  font-weight: 800;
  padding-bottom: 40px;
}
.ant-card .ant-card-meta-detail >div:not(:last-child) {
  margin-bottom: 8px;
  text-align: center;
  font-weight: 800;
  font-size: 25px;
  color: #086AD8;
}
.ant-card.ant-card-bordered.ant-card-hoverable {
  min-height: 800px;
}
.ant-card-body h3 {
  text-align: center;
  padding-bottom: 20px;
}
.atThumbnail{
  display: flex;
  justify-content: center;
}
.atThumbnail img{

  width: 50%;

}
.lifeCards .col-sm-4{
  margin: 20px 0;
}

.thumbnail-container {
  position: relative;
  text-align: center;
}

.thumbnail-image {
  width: 50%;             display: block;        
  margin: 0 auto;        
}

.wave {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background-color: #086AD8;   border-radius: 50%; 
  transform: translate(-50%, -50%);
  animation: wave 1.5s infinite ease-out; 
  z-index: 1; 
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px !important; 
  color: #086AD8;   
  z-index: 2;    
  cursor: pointer;
}
iframe {
  width: 100%;
}
.videoModal span.ant-modal-close-x {
  display: none !important;
}
.anticon svg {
  display: inline-block;
  font-size: 100px !important;
}
@keyframes wave {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}
.growth .ant-card.ant-card-bordered.ant-card-hoverable {
  min-height: 1300px;
}
.ant-card-meta-description{
  line-height: 30px;
}
.swiper.swiper-initialized.swiper-horizontal.mySwiper.slidesbyvcan {
  width: 70%;
}
ul li.liissttt{
  margin-bottom: 16px;
  margin-left: 10px;
}
p.noJOb {
  width: 100% !important;
  text-align: center;
  padding: 50px;
  font-weight: 600;
  font-size: 24px !important;
}
.ant-picker-range .ant-picker-active-bar {
  background-color: transparent !important;
}
ul li.liissttt img{
  margin-right: 8px;
}
ul li.liissttt a{
  color: #000 !important;
}
.container.copyright{
  max-width: 100%;
  background: #0e76bc;
  padding: 20px 10%;
  text-align: center;
  color: #fff !important;
}
.container.copyright p{
  margin-bottom: 0;
  width: 100% !important;
}
.container.copyright a{
  color: #fff !important;
  cursor: pointer !important;
}
.pastPriceForm .ant-picker input {
  background-color: #fff !important;
  border: 1px solid #fff;
  border-radius: 8px;
  margin-right: 10px;
  padding: 13px !important;
}
.pastPriceForm .ant-picker input:hover {
  padding: 13px !important;
  border: 1px solid #fff !important;
}
.col-sm-4 .react-card-front button img {
  height: 530px;
  object-fit: cover;
}
.pdfs {
  padding-bottom: 4%;
}
.pdfs img {
  width: 100%;
}
.pdfs h3 {
  color: #000 !important;
  text-align: left !important;
  font-size: 16px;
  font-weight: 700;
  padding-top: 12px;
}
.container.mypdfss {
  width: 80%;
  margin: auto;
  padding-top: 30px;
}
.container.foots.banner:before {
  content: unset !important;
}
.secp-img {
  margin-top: 5px;
}
.container.foots.banner {
  height: auto !important;
}
.container.foots.banner .col-sm-6.buttonscta {
  display: flex;
}
.col-sm-12.shareholding.myntn p {
  margin-left: 0 !important;
}
.col-sm-12.atThumbnail{
  padding-bottom: 80px;
}
.menusildes h3 {
  width: 80%;
  border-radius: 10px;
}
.anticon-eye {
  color: #000 !important;
}
div#coc {
  margin-bottom: 40px;
}
.icon.anticon svg{
  font-size: 102px !important;
  color: #fff !important;
}
.anticon svg {
  display: inline-block;
  font-size: 22px !important;
}
.ant-input-affix-wrapper {
  padding: 10px 11px;
}
.loginForm .ant-form-item {
  margin: 25px 0;
}
.loginButton button {
  margin: 32px 0;
}
.loginForm h6 {
  font-size: 1rem;
  color: #fff;
  text-align: center;
  padding: 10px 0px;
  letter-spacing: 1px;
  cursor: pointer;
}
.loginForm h6 a {
  color: #fff;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #0889e3;
}
.ant-table-tbody tr {
  cursor: pointer;
}
.ant-table-tbody tr:nth-child(odd) {
  background-color: #c3c3c3;
}
.ant-table-cell-row-hover {
  background: url("../Assets/green.png") !important;
  color: #fff !important;
}
.ant-table-summary {
  display: none;
}
.sub.complainforms.whistleforms.pastPriceForm {
  width: 50%;
  background: #0889e3;
  padding: 30px 20px;
  border-radius: 20px;
  margin: 40px auto 60px;
}
.ant-picker-range .ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
  display: none;
}
.col-sm-7 .supliment ::-webkit-scrollbar {
  display: none;
}
button p.designation {
  width: 100% !important;
}
/* .sub.complainforms.whistleforms.fundPrices {
  width: 55%;
} */
.widraw {
  background-color: #0fb583;
}
.widraw h2 {
  text-align: center !important;
  padding-top: 5%;
}
.container.tabss.motor.whistle {
  padding-top: 0 !important;
}
.row.lifeCards {
  align-items: flex-start !important;
}

.your-form-container {
  display: flex;
}
.steps-container {
  flex: 1;
  padding: 20px;
}
.form-container {
  flex: 2;
  padding: 20px;
}
.button-container {
  margin-top: 16px;
  text-align: right;
}
.steping {
  background-image: url("../Assets/Forms.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10% 2% !important;
}
.stepsform {
  padding: 0 !important;
}
.ant-steps {
  text-align: initial;
  flex-direction: column;
}
.ant-steps div {
  padding-left: 0 !important;
  padding-bottom: 2%;
}
/* .ant-steps .ant-steps-item-title::after{
    content: unset;
} */
.stepforms {
  background-color: #0e76bc;
  padding: 10% 5% !important;
}
.stepforms.step {
  background-color: transparent;
  padding: 10% 5% !important;
}
.container-fluid.stepsform .row {
  align-items: unset !important;
}
.steping h3 {
  font-size: 21px !important;
  padding-bottom: 10% !important;
}
.ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-title {
  color: #fff !important;
  font-size: 21px !important;
  font-weight: 600;
}
.ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-icon {
  width: 48px !important;
  height: 48px !important;
  line-height: 43px !important;
  font-size: 21px;
  transition: background-color 0.3s, border-color 0.3s;
  transition: all 0.3s ease-in-out;
}
.ant-steps-item-container {
  display: flex;
  align-items: baseline;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #ffffff3b;
  border-color: #ffffff3b;
}
.ant-steps-item-icon {
  margin-right: 25px !important;
}
.ant-steps-item-title {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 500;
}
.ant-steps-item-icon {
  width: 38px !important;
  height: 38px !important;
  line-height: 43px !important;
  font-size: 18px;
  transition: background-color 0.3s, border-color 0.3s;
  transition: all 0.3s ease-in-out;
}
.ant-steps svg {
  display: inline-block;
  font-size: 22px !important;
  margin-top: -10px;
  color: #0c551d;
}
.ant-steps-item.ant-steps-item-finish .ant-steps-item-title {
  color: #fff !important;
  font-size: 21px !important;
  font-weight: 600;
}
.ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
  width: 48px !important;
  height: 48px !important;
  line-height: 43px !important;
  font-size: 21px;
  transition: background-color 0.3s, border-color 0.3s;
  transition: all 0.3s ease-in-out;
}
.ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #ffffff3b !important;
  border-color: #ffffff3b !important;
}
span.ant-steps-icon {
  color: #fff !important;
}
.ant-steps .ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  inset-inline-start: 100%;
  display: block;
  width: 3px;
  height: 40px;
  background: rgb(5 5 5);
  left: -22%;
  content: "";
  top: 110%;
}
.col-sm-12.contriFreq .ant-select.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
  height: 55px !important;
}
.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: rgb(5 5 5 / 14%) !important;
}
.stepforms input {
  padding: 15px 10px !important;
  border-radius: 10px;
}
.stepforms span.ant-picker-suffix {
  padding: 0 !important;
}
.mynutonsstep {
  text-align: right !important;
}
.mynutonsstep.calculateeeForm button {
  margin-top: 5% !important;
}
.mynutonsstep button {
  margin-top: 15%;
  padding: 8px 40px;
  font-size: 16px;
  background-color: #0c551d !important;
  border-radius: 10px;
  height: auto !important;
  color: #fff !important;
  border: unset !important;
}
.steptext h3 {
  color: #fff !important;
  font-size: 38px !important;
}
.stepnum p {
  color: #fff;
  opacity: 0.7;
  font-size: 38px;
  font-weight: 700;
}
.stepnum p span {
  color: #fff;
  opacity: 0.7;
  font-size: 26px;
  font-weight: 700;
}
.radios .ant-form-item {
  background: #fff;
  border-radius: 10px;
  padding: 15px 10px;
}
.radios .ant-row.ant-form-item-row {
  display: flex;
  flex-flow: initial;
}
.radios label {
  width: 350px;
  padding: 0 !important;
  font-weight: 700;
  font-size: 16px !important;
}
.radios .ant-col.ant-form-item-label {
  padding: 0 !important;
  display: flex;
}
.radios label.ant-radio-wrapper {
  width: auto !important;
}
.radios .ant-form-item-control-input {
  text-align: end !important;
}
div#extra-benifits {
  padding: 0 20px;
}
.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #191a1a29 !important;
}
.col-sm-6.radios {
  margin-top: 1%;
}
.col-sm-8.stepforms.step {
  width: 66.66666667% !important;
}
.formHeader.col-sm-12 h4 {
  color: #0fb583;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
  padding: 15px 0;
  z-index: -1;
}
.stepsform input,
.ant-select-selector,
.ant-picker {
  box-shadow: 0px 4px 10px 0px rgba(28, 117, 188, 0.16);
}
.ant-picker input {
  box-shadow: none !important;
}
.otpModal {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-sm-6.forrPadd.selectorrr .ant-select.ant-select-in-form-item {
  height: 55px !important;
}
.row.whistleFormRow {
  align-items: flex-start !important;
}
.row.whistleFormRow .col-sm-6.leftform.formstyle input,
.row.whistleFormRow .col-sm-6.leftform.formstyle textarea {
  margin: 0 !important;
}
.row.whistleFormRow .col-sm-6.leftform.formstyle .ant-form-item {
  margin-bottom: 10px !important;
}
.col-sm-6.forrPadd.selectorrr .ant-select .ant-select-arrow {
  margin-top: -6px !important;
}
.col-sm-12.heads.text.mymaxheight.widraw.po .ant-table-container {
  border-radius: 8px !important;
  border-bottom: unset !important;
}
.col-sm-12.newnoPad p {
  padding-top: 5px;
}
.col-sm-12.formstyle.rightform .ant-table-container {
  border-radius: 8px !important;
  border-bottom: unset !important;
}
.col-sm-12.formstyle.rightform .ant-table-wrapper .ant-table {
  border-radius: 8px !important;
  overflow: hidden;
}
.col-sm-12.heads.text.mymaxheight.widraw.po .ant-table-wrapper .ant-table {
  border-radius: 8px !important;
  overflow: hidden;
}
.otpModal input {
  width: 4rem;
  margin-right: 0.5rem;
  height: 4rem;
  font-size: 20px;
  text-align: center;
  border: 3px solid #0fb583 !important;
}
.otpModal form.ant-form.ant-form-horizontal {
  display: flex !important;
}
.container.modalForm h4 {
  color: #0fb583;
  font-size: 25px;
  font-weight: 700;
  padding: 10px 0px;
  text-transform: uppercase;
}
.benefitBox.col-sm-3 {
  margin: 20px 0px;
  border-radius: 20px;
  padding: 0px 6px;
  height: 0;
  transition: all ease-in-out 500ms;
  width: 30%;
  margin-left: 20px;
  margin-bottom: 55px !important;
}
.benefitBox.col-sm-3 label p {
  margin: 0 !important;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
  padding-left: 10px;
  color: #000 !important;
}
.greenDiv {
  background-color: #0fb583;
}
.greenDiv p {
  color: #fff !important;
}
.col-sm-3.benefitBox p {
  text-align: left;
  font-size: 11px;
  color: black;
}
.modalForm table {
  width: 100%;
}
.modalForm.container table input,
select {
  border-radius: 20px;
  padding: 10px;
}
.modalForm.container table thead {
  background: #0fb583;
  color: #fff;
}
.modalForm.container table thead th {
  padding: 20px 10px;
}
.row.recommendations.buttonss.more {
  display: none;
}
.healthSection.container {
  border-radius: 20px;
  background: linear-gradient(to bottom, #0fb583, #60d9a5);
  padding: 60px 20px;
  color: #fff;
}
.healthSection.container h4 {
  text-align: center;
  padding: 20px 0;
}
.modalSelect.react-reveal.col-sm-4.leftform.formstyle {
  padding: 0;
  margin: 0 !important;
  height: 50px;
  align-items: bottom;
  display: flex;
  text-align: bottom !important;
}
.col-sm-6 .Info {
  height: 300px;
  background-color: #0889e3;
  border-radius: 20px;
  padding: 20px;
  margin: 10px 0px;
}
.col-sm-6 .Info h4 {
  color: #fff;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.col-sm-6 .Info h6 {
  color: #fff;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-align: center;
  font-size: 15p;
}
.step6Tab table {
  width: 100%;
}
.step6Tab table thead {
  background: #b7b7b7 !important;
}
.step6Tab table thead th {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  padding: 20px 10px;
  text-align: center;
}
.step6Tab table tbody tr {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  background: #D9D9D9;
}
.step6Tab table {
  width: 100%;
  padding-bottom: 20px;
  border-collapse: separate;
  border-spacing: 0 4px;
}
.col-sm-12.step6Tab td {
  padding: 20px 10px;
}
.verifyBtn {
  text-align: end;
}
/* .col-sm-12.step6Tab tr :nth-child(even) {
  background-color: red;
} */
 /* Dashbord CSS */
.container-fluid.header.userHeader {
  background: url(http://localhost:3000/static/media/white.02a83dcâ€¦.png) !important;
  align-items: center !important;
  padding-left: 50px;
  padding-right: 50px;
  position: inherit;
  background: #D9D9D9 !important;
}
.container-fluid.header.userHeader img {
  background-color: transparent !important;
}
.container-fluid.header.userHeader .menu h6 {
  float: right;
  color: #000;
  opacity: 0.6;
  font-size: 13.432px;
  padding: 10px 20px;
}
.blueBtn {
  border-radius: 6px;
  background: #0e76bc;
  color: #fff;
  border: none;
  margin: 0 10px;
  padding: 11.939px 29.848px;
}
.greenBtn {
  border-radius: 6px;
  background: #0c551d;
  color: #fff;
  border: none;
  margin: 0 15px;
  padding: 11.939px 29.848px;
}
.dashbord {
    min-height: 87.3vh;
  height: 100%;
}
.row.profile {
  padding-top: 50px;
  align-items: unset !important;
}
.row.profile.claim .col-sm-6 h2 {
    color: #1C75BC;
}
.row.profile .col-sm-6 h2 {
    font-size: 33px;
  font-style: normal;
  font-weight: 700;
  color: #1C75BC;
  z-index: 0;
}
.row.profile .col-sm-6 h6 {
    font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.row.profile .col-sm-6 p {
    font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
  font-size: 10px;
}
.row.profile .col-sm-6 h6 span {
  color: #0fb583;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
}
.row.profile .col-sm-6 h6 span img {
  cursor: pointer;
}
.row.profile .col-sm-6 h2 span img {
  cursor: pointer;
}
.dashboardTabs .box {
  cursor: pointer;
  border: 0.924px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 5.544578552246094px 9.24096393585205px 18.4819278717041px 0px rgba(0, 0, 0, 0.15);
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 92%;
  transition: all ease-in-out 1s;
}
.dashboardTabs .box:hover {
  background-color: #0fb583;
  border-radius: 20px;
}
.changeMind.col-sm-12 .col-sm-4:first-child button {
  background-color: #04A753 !important;
  color: #fff !important;
  transition: all ease-in-out 500ms;
  
}
.changeMind.col-sm-12 .col-sm-4:first-child button:hover {
  background-color: #fff !important;
  color: #04A753 !important;
  transition: all ease-in-out 500ms;
}
.dashboardTabs .box:hover .dashboardTabs .box p {
  color: #fff;
}
.col-sm-12.pastPriceForm .ant-select .ant-select-arrow {
  margin-top: -6px !important;
}
.col-sm-12.pastPriceForm .ant-select-selector {
  height: 50px !important;
  margin-bottom: 0 !important;
}
.col-sm-12.pastPriceForm .mbbbbb {
  margin-bottom: 10px !important;
}
.dashboardTabs .box p {
  color: #000;
  font-size: 21.254px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
}
.row.withDrawl .col-sm-12.changeMind {
  background-color: #1C75BC;
  padding: 30px 20px;
  border-radius: 10px;
  margin: 0 auto;
  width: 100%;
  margin: 25px 0;
  text-align: center;
}
.row.withDrawl .col-sm-12.changeMind .row {
  justify-content: center;
}
.col-sm-6.forrPadd {
  padding: 0 12px !important;
}
.row.withDrawl .col-sm-12 p {
    color: rgba(0, 167, 157, 1);
  font-weight: 500;
  padding-left: 20px;
}
.row.withDrawl .col-sm-12.changeMind p {
    color: #fff;
  
}
.row.withDrawl .col-sm-12.changeMind button {
    width: 100%;
  border: none;
  height: 70px !important;
  color: #04A753;
  transition: all ease-in-out 500ms;
}
.unitPrice .col-sm-3 button {
  width: 100%;
  
}
.unitPrice .col-sm-3 div {
  border: 1px solid rgb(204, 204, 204);
  text-align: center;
  padding-top: 15px;
  border-radius: 10px;
  margin-top: 2px;
}
.unitPrice .col-sm-3 div:first-child {
  background-color: #D9D9D9 !important;
}
.TableSection1.unitPrice h2 {
  padding: 20px 0;
}
.unitPrice h2 {
    color: #1C75BC;
  font-weight: 700;
  font-size: 34px;
}
.row.profile.status .col-sm-12 h6 {
    font-weight: 400;
  font-size: 16px;
  
}
.row.profile.status .col-sm-12 h2 {
    color: #0889e3;
  font-weight: 700;
  font-size: 33px;
}
.row.profile.status .col-sm-12 h3 {
  font-weight: 700;
  font-size: 23px;
}
.row.profile.status .col-sm-12 h4 {
  font-weight: 700;
  font-size: 25px;
  color: #0FB583;
}
.row.withDrawl .col-sm-12.changeMind button:hover {
  background-color: #04A753;
}
.col-sm-3 .lastBox {
  height: 561px;
  background: #0fb583;
  position: absolute;
  width: 98%;
  top: -130px;
  box-shadow: 5.544578552246094px 9.24096393585205px 18.4819278717041px 0px rgba(0, 0, 0, 0.15);
}
.claimForm .withdrawlLine .innerLine {
  width: 100% !important;
}
.NomineeForm span.ant-picker-suffix {
  background-color: transparent;
  z-index: 0;
}
.NomineeForm .ant-picker input {
  margin: 0 !important;
}
.NomineeForm .ant-picker {
  padding: 30px 0 !important;
  box-shadow: none !important;
  border: 1px solid rgb(204, 204, 204);
  margin-top: 20px;
}
.NomineeForm input, .NomineeForm select {
  border: 1px solid rgb(204, 204, 204);
  padding: 20px 20px !important;
  font-size: 16px;
  box-shadow: none !important;
}
.withDrawl.claimForm.NomineeForm.row .ant-select-selector {
  padding: 31px 10px !important;
  box-shadow: none;
}
.withDrawl.claimForm.NomineeForm.row input {
  margin-top: 20px;
}
.withDrawl.claimForm.NomineeForm.row .col-sm-12 button {
  width: 33%;
}
.tabss .col-sm-12.heads.text p.elemTxt{
  font-size: 24px !important;
  margin-bottom: 20px;
}
.withDrawl.claimForm.NomineeForm.row .dashboardBn {
  margin-top: 28px;
}
.lastBox .lastBoxSection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33%;
  color: #fff;
}
.lastBox .lastBoxSection p {
  margin-left: 12px;
  text-align: unset;
  color: #fff;
  margin-top: 15px;
}
.termContent label.ant-checkbox-wrapper {
  width: 40%;
}
.userSidebar {
  background: #0c551d;
  width: 100%;
  height: 87.8vh !important;
  margin-left: -12px;
  position: relative;
}
.row.container.dashboardTabs {
  width: 80%;
}
.dashboardSection {
  height: 80vh;
}
.withdrawlLine {
  width: 100%;
  margin: 30px 0px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid rgb(201, 201, 201);
}
.TableSection table thead {
  border: 1px solid red !important;
}
.TableSection table {
  border-collapse: collapse;
}
.innerLine h6 {
  font-weight: 700;
  color: #00a79d;
  padding-left: 10px;
  font-size: 18px;
  padding-top: 7px;
  font-style: normal;
}
.innerLine {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 6px 39px;
}
.innerLine p {
  color: #bdbaba !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.withDrawl .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}
.innerLine input {
  width: 100%;
  border: none;
  margin: 13px 0px;
}
.innerLine input::placeholder {
  color: #bdbaba;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.ant-form-item {
  margin-bottom: 0px;
}
.sideBarSec {
  width: 62%;
  margin: 0 auto;
  text-align: left;
  padding-top: 25px;
}
.sideBarSec a {
  text-decoration: none;
  color: #fff;
}
.sideBarSec a p {
  padding-top: 40px;
}
.f {
  position: absolute;
  bottom: 3%;
  left: 11%;
}
.f img {
  margin: 0 10px;
}
.TableSection table {
  width: 100%;
  padding-bottom: 20px;
  border-collapse: separate;
  border-spacing: 0 4px;
}
.TableSection thead {
  background-color: #fff;
}
.TableSection .fst {
  border-radius: 10px 0 0 10px;
}
.TableSection thead .fst {
  color: black;
  font-weight: 600;
}
.TableSection .lst {
  border-radius: 0 10px 10px 0;
}
.TableSection th {
  font-weight: 400;
  color: #bdbaba;
  font-size: 15px;
  line-height: 110.9%;
}
.TableSection thead th {
  padding-top: 24px;
  padding-bottom: 25px;
  padding-left: 20px;
}
.TableSection tbody td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}
.TableSection tbody td {
  color: #fff;
}
.TableSection tbody tr {
  background: rgba(0, 0, 0, 0.2);
}
.btnSection {
  text-align: end;
}
.btnSection button {
  background: #0c551d;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 15px 100px;
}
.dashboardBn {
  width: 100%;
  padding: 17px;
  border: none;
  border-radius: 10px;
  background: #0c551d;
  color: #fff;
}
.TableSection1 tbody td {
  padding-top: 22px;
  padding-bottom: 22px;
}
.col-sm-10.container.dashboardSection {
  overflow: auto;
}
td.fst img {
  margin-right: 10px;
}
.greyBtn {
  width: 100%;
  padding: 17px;
  border: none;
  border-radius: 10px;
  background: #484848;
  color: #fff;
}
.sideSectionCanvas {
  display: none;
}
.contributionTable table {
  background-color: gainsboro;
  border-radius: 10px;
  width: 100%;
  text-align: center;
}
.contributionTable table td,
th {
  padding: 10px 7px;
  cursor: pointer;
  border: none;
  border-collapse: collapse;
  text-align: center;
}
.contributionTable table tbody tr:hover {
  background-color: #0889e3;
  color: #fff;
  transform: translate(2);
}
.contributionTable table thead tr {
  background-color: #0c551d;
  color: #fff;
}
.col-sm-12.step6btn {
  margin-top: 30px;
}
.col-sm-12.step6btn button,
.col-sm-12.step6btn button.ant-btn.ant-btn-default:hover {
  border-radius: 10px;
  color: #000 !important;
  border: 1px solid #0FB583 !important;
  padding: 0 80px !important;
}
.col-sm-12.contributionTable table {
  width: 100%;
}
.col-sm-12.contributionTable table th {
  text-align: center;
}
.ant-input-number {
  width: 100%;
  border: unset;
}
input#Annual-income,
input#Other-income,
input#Expenses-last-year,
input#expenses-current-year {
  box-shadow: none;
}
.tabLastSection {
  position: relative;
}
.editModal .ant-row.ant-form-item-row {
  display: block !important;
}
.editModal input {
  padding: 15px 15px !important;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(28, 117, 188, 0.16);
}
.editModal span.ant-picker-suffix {
  padding: 0px !important;
}
.col-sm-8.stepforms.step.steps label {
  margin-top: 10px !important;
}
span.ant-slider-mark-text {
  width: 25px;
}
span.ant-select-selection-search input {
  box-shadow: unset;
}
span.ant-input-group-addon .ant-select {
  box-shadow: 0px 4px 10px 0px rgba(28, 117, 188, 0.16) !important;
  border-radius: 0 !important;
  background: #fff;
  border: 1px solid #d9d9d9 !important;
  border-radius: 0 !important;
  border-right: 0;
  border-radius: 10px 0px 0px 10px !important;
}
.col-sm-8.stepforms.step.steps .ant-select-selector {
  margin: 0 !important;
}
.col-sm-8.stepforms.step.steps .anticon svg {
  display: inline-block;
  font-size: 15px !important;
}
.col-sm-8.stepforms.step.steps .ant-select-selector {
  height: 55px !important;
  display: flex;
  align-items: center;
  border-radius: 10px !important;
  box-shadow: 0px 4px 10px 0px rgba(28, 117, 188, 0.16) !important;
}
.col-sm-8.stepforms.step.steps .ant-picker {
  height: 55px !important;
  display: flex;
  align-items: center;
  border-radius: 10px !important;
  box-shadow: 0px 4px 10px 0px rgba(28, 117, 188, 0.16) !important;
  padding-top: 10px !important;
}
.col-sm-8.stepforms.step.steps input:hover {
  border-color: #d9d9d9 !important;
}
.ant-select.ant-select-in-form-item:hover .ant-select-selector {
  border-color: #d9d9d9 !important;
}
.ant-select:hover .ant-select-selector {
  border-color: #d9d9d9 !important;
}
span.ant-tag.ant-tag-blue {
  background: transparent;
  border: unset;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.ant-modal-body td {
  padding: 10px 10px;
}
.ant-modal-body tr {
  box-shadow: 0px 4px 3px 0px rgba(28, 117, 188, 0.16);
}
.ant-modal-footer .ant-btn-primary {
  background: #0fb583;
  width: 140px;
  height: 40px;
  font-size: 18px;
  margin-top: 13px;
}
.ant-modal-footer .ant-btn-default {
  background: #1c75bc;
  width: 140px;
  color: #fff;
  height: 40px;
  font-size: 18px;
  margin-top: 13px;
}
.ant-modal-footer .ant-btn-default:hover {
  color: #fff !important;
}
.ant-modal-footer .ant-btn-primary:hover {
  background: #0fb583 !important;
}
.healthSection.container p {
  margin: 0;
}
.col-sm-7.audp.lleeegalAdvisdor p {
  font-size: 22px;
}
.col-sm-7.audp.lleeegalAdvisdor h2{
  font-size: 36px;
}
.healthSection.container .ant-form-item {
  margin-bottom: 35px;
  margin-top: -5px;
}
.container.governanceData.shariahData.govdirectors {
  padding: 0 !important;
}
.shariahData h4.dname {
  color: #0e76bc;
  font-size: 24px !important;
  font-weight: 700;
  margin-bottom: 0;
  padding-top: 10px;
  text-align: left;
} 
.shariahData p.designation {
  padding-left: 0;
}
.heads .sub.shariahtabs .container.governanceData.shariahData.govdirectors button.backbox {
  background: #0c551d !important;
}
.container.governanceData.shariahData.govdirectors .row .col-sm-4{
  width: 35%;
}
.healthSection.container .ant-form-item span {
  color: #fff;
  font-weight: 600;
}
.ant-modal-content .anticon svg {
  font-size: 16px !important;
}
.ant-modal-content .ant-select {
  width: 100% !important;
}
.ant-modal-content .ant-select-selector {
  margin: 0 !important;
  border: unset !important;
  box-shadow: unset;
}
.ant-modal-content input {
  margin: 0 !important;
  border: unset !important;
  box-shadow: unset;
  border-bottom: 1px solid #efefef !important;
  border-radius: unset !important;
}
.ant-modal-content .ant-upload.ant-upload-select button {
  color: #fff;
  background: #0fb583;
  height: 35px !important;
}
.ant-modal-content .ant-upload.ant-upload-select button:hover {
  color: #fff !important;
  background: #1c75bc !important;
  height: 35px !important;
}
.ant-modal-content p {
  margin-bottom: 0 !important;
}
.col-sm-8.stepforms.step.steps .ant-input-number input {
  box-shadow: 0px 4px 10px 0px rgba(28, 117, 188, 0.16) !important;
  padding: 15px 10px !important;
  border: 1px solid #d9d9d9 !important;
}
.col-sm-8.stepforms.step.steps .ant-input-number .anticon svg {
  display: inline-block;
  font-size: 12px !important;
}
.TermsSection.container-fluid.row .col-sm-4 {
  border-right: 1px solid #efefef !important;
  width: 32%;
  padding-right: 20px;
  padding-left: 10px;
}
.TermsSection.container-fluid.row .col-sm-4 p {
  margin: 0;
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
}
.recom {
  padding-bottom: 25px;
}
button.ant-btn.ant-btn-default {
  height: 40px !important;
  font-size: 16px;
  padding: 0 25px;
}
button.ant-btn.ant-btn-default:hover {
  color: #fff !important;
}
.col-sm-6.step6btn button {
  background-color: #0c551d;
  color: #fff;
  min-width: 20%;
}
.PhoneInput input {
  border-color: #d9d9d9 !important;
  border: 1px solid #d9d9d9;
  border-radius: 0px 10px 10px 0px !important;
  border-left: 0 !important;
}
.PhoneInput input:focus {
  outline: unset !important;
  border-color: #d9d9d9;
}
.PhoneInputCountry {
  border: 1px solid #d9d9d9;
  margin: 0 !important;
  padding: 0 10px;
  border-radius: 10px 0px 0px 10px;
  box-shadow: 0px 4px 10px 0px rgba(28, 117, 188, 0.16);
  border-right: 0 !important;
}
.termContent .ant-input-number {
  width: 15% !important;
}
.uploadsbtns .ant-form-item button {
  color: #fff !important;
  background: #0fb583 !important;
  height: 50px !important;
}
.col-sm-8.stepforms.step.steps .uploadsbtns .ant-form-item button .anticon svg {
  display: inline-block;
  font-size: 25px !important;
  padding-bottom: 6px;
}
.ratingSection .col-sm-12 h5 {
  color: #000;
  font-size: 27.58px;
  font-weight: 700;
  padding: 5px 0;
}
.ratingSection .col-sm-12 p {
  color: #000;
  font-weight: 400;
  opacity: 0.6;
}
.ratingSection .col-sm-6 .row {
  border-radius: 10px;
  border: 1px solid rgba(28, 117, 188, 0.16);
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(28, 117, 188, 0.16);
  margin: 10px 0;
  align-items: center !important;
}
.ratingSection .col-sm-6 .row .col-sm-3 {
  padding: 0;
}
.ratingSection .col-sm-6 .row .col-sm-3 input {
  text-align: center;
  font-size: larger;
  border-radius: 10px;
  background: #f5f5f5;
}
.ratingSection .col-sm-6 .row .col-sm-9 p {
  color: #a39e9e;
  font-size: 13px;
  font-weight: 400;
  padding-top: 15px;
}
.row.recommendations {
  box-shadow: none !important;
  border: none !important;
}
/* .button-container.app-buttons {
  margin-top: 10% !important;
} */
.button-container.app-buttons button{
  background: #0e76bc !important;
  border: unset !important;
  color: #fff !important;
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
  border-radius: 10px;
  height: auto !important;
  padding: 10px 30px;
}
.row.recommendations button {
  border-radius: 10px;
  width: fit-content;
  background-color: #f5f5f5;
}
.recoms .ant-btn:hover {
  background-color: #1890ff;
  border-color: #1890ff;
}
.selected-button {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
  color: #ffff !important;
}
.termsSection {
  padding-top: 40px;
}
.illustrator span.ant-avatar.ant-avatar-circle.ant-avatar-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.illustrator {
  border-radius: 20px;
  background: #0FB583;
  padding: 20px 0px;
  color: #fff;
  margin: 20px 35px;
  height: 360px;
}
.illustrator .row .col-sm-6 {
  padding: 20px 10px;
}
.policyPage {
  text-align: center;
  padding: 30px 0;
}
.policyPage .policyContent.col-sm-12 h3 {
  color: #0c551d;
  font-weight: 700;
  font-size: 40px;
}
.policyPage .policyContent.col-sm-12 p {
  font-weight: 400;
  font-size: 18px;
}
.policyPage .col-sm-12 {
  padding: 20px 0;
}
.policyPage .policyContent.col-sm-12 h3 span {
  color: #0889e3;
  font-weight: 700;
}
.policyPage .col-sm-12 h4 {
  font-weight: 700;
  font-size: 40px;
}
.policyPage.container .accordion-button::after {
  display: none !important;
}
.policyPage.container .accordion-button {
  font-size: 22px;
  color: #ababab !important;
  position: relative;
  overflow-wrap: break-word;
  white-space: normal;
  line-height: 1.2;
  font-weight: 600;
  border-radius: 5px;
  margin-bottom: 0;
  gap: 5px;
  background: #ffffff;
}
.policyPage.container .accordion-body{
  padding-left: 50px;
  padding-right: 50px;
}
.container.policyPage .accordion-item {
  margin-bottom: 10px;
}
.policyPage.container .accordion-button svg {
  margin: 0 10px;
}
.policyPage.container .accordion-body,
.policyPage.container .accordion-button {
  text-align: left;
  background-color: transparent !important;
  color: #000 !important;
}
.policyPage.container .accordion-button:hover, .policyPage.container .accordion-button:hover svg {
  color: #0889e3 !important;
}
.policyPage.container .accordion-body ul li {
  list-style: unset;
  color: #000;
}
.relationModal .col-sm-12 button {
  background-color: #0fb583;
  margin: 20px 0;
}
.relationModal .col-sm-12.modalbtn {
  text-align: center;
}
.homemenu .swiper-pagination {
  display: block !important;
}
.homemenu .swiper-pagination {
  position: unset !important;
}
.col-sm-3.last {
  padding-top: 35px;
}
.tabss .col-sm-12.heads.text .col-sm-12.formstyle.rightform h3 {
  font-size: 48px !important;
  padding-bottom: 0px;
  font-weight: 700 !important;
  text-align: center;
  padding-top: 78px;
}
.col-sm-12.heads.text.mymaxheight.complainbox.contactform.contactSection {
  background-image: url(https://asset.salaamtakaful.com/GeneralAssets/individual/Rectangle135.jpg) !important;
  background-position: 50%;
  background-repeat: no-repeat;
}
.complainFormstyle,
.contactFormstyle {
  background-color: #0fb583 !important;
  border-radius: 20px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .24);
  margin-top: 50px !important;
}
.col-sm-12.heads.text.mymaxheight.complainbox.contactform.contactSection .col-sm-6.formstyle {
  padding: 5%;
}
.col-sm-6.formstyle.contactFormstyle H3 {
  TEXT-ALIGN: left;
  font-size: 49px;
  font-weight: 700;
  line-height: 65px;
}
.govbanner.contact {
  background-image: url("../Assets/gvbanner.jfif") !important;
  background-size: cover !important;
}
.col-sm-12.heads.text.mymaxheight.maps .sub.complainforms {
  width: 100%;
  margin: auto;
  padding: 0;
}
.col-sm-12.heads.text.mymaxheight.maps {
  padding-bottom: 0 !important;
}
.swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu .swiper-button-next {
  right: 0% !important;
  left: auto !important;
  top: 90% !important;
}

.swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu .swiper-button-prev {
  left: auto !important;
  right: 5% !important;
  top: 90% !important;
}
.swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu .swiper-button-next:after,
.swiper.swiper-initialized.swiper-horizontal.mySwiper.prdocyMenu .swiper-button-prev:after {
  font-size: 24px !important;
  border: unset !important;
  border-radius: 0 !important;
  background: transparent !important;
}
.vision h2 {
  padding-bottom: 5px !important;
}
.row.whoweare.mission.left {
  padding: 5% 0 !important;
}

.heads.text.news .row{
  display: flex;
  justify-content: center;
}
.menusildes img {
  /* height: 500px; */
  object-fit: cover;
}
.menusildes.VIDEEEOS h3 {
  margin-top: -7px !important;
  width: 100% !important;
}
.menusildes video {
  height: 500px;
  object-fit: cover;
}
h2.shariahhead {
  color: #fff !important;
  padding-bottom: 30px !important;
  font-size: 62px;
}
.illustrator .illustrationImage.col-sm-4 {
  margin-left: -83px;
  margin-top: 85px !important;
  width: 37%;
}
.illustrator .col-sm-12 {
  padding: 10px 10px;
}
.illustrationPage.stepforms.step {
  background-color: transparent;
  padding: 10% 3% !important;
}
.illustrationPage .col-sm-4 .ant-checkbox+span {
  color: #00A79D;
  font-size: 15px;
  font-weight: 400;
}
.ant-select .ant-select-arrow {
  margin-top: 6px;
}
.editBeneficery .ant-form-item .ant-form-item-label {
    text-align: left;
}
.container-fluid.shares.marbot .col-sm-7.audp h2 {
  text-align: left !important;
  color: #fff;
}
.row.mySuppliment {
    width: 100% !important;
}
.row.mySuppliment img {
  margin: 0 !important;
}
.col-sm-8.stepforms.step.steps label .anticon svg,
.col-sm-8.stepforms.step.steps h5 .anticon svg,
.col-sm-8.stepforms.step.steps h4 .anticon svg {
  display: inline-block;
  font-size: 15px !important;
  margin-left: 10px;
  cursor: pointer;
}
.NomineeForm .ant-input-disabled {
  background: rgba(28, 117, 188, 0.16) !important;
  color: #000 !important;
}
.combtn {
  background-color: #0c551d !important;
  color: #fff !important;
  padding: 3px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid;
  width: 50%;
  justify-content: center;
  height: 45px;
}
.container-fluid.shares.marbot .col-sm-7.audp h2 {
  margin-left: 0 !important;
}
.container-fluid.shares.marbot .col-sm-7.audp h3 {
  margin-left: 0 !important;
}
div#coc h2 {
  text-align: center;
}
.col-sm-12.shareholding.myntn a {
  justify-content: center !important;
  text-align: center;
}
#directors .col-sm-4:first-child {
  width: 50%;
  text-align: center;
}
#directors .col-sm-4:nth-child(2) {
  width: 50%;
  text-align: center;
}
.addsa img {
  width: 30% !important;
}
.addsa .col-sm-3 {
  text-align: center;
  height: 270px;
}
.addsa p {
  color: #fff !important;
}
.tabss .col-sm-12.heads.text .addsa p {
  color: #fff !important;
  font-size: 18px !important;
}
.tabss .col-sm-12.heads.text .addistionslas {
  text-align: center !important;
  margin-bottom: 65px !important;
}
.dashboardBn1 {
  background-color: rgba(210, 210, 210, 1);
  color: #fff;
  width: 18% !important;
}
.col-sm-12.text {
    padding-top: 30px;
  font-weight: 500;
}
.statement.row {
  border: 1px solid rgb(204, 204, 204);
  margin: 0;
  padding: 10px 5px;
  align-items: center;
  border-radius: 4px;
  margin-top: 25px;
}
.statement.row p {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  color: rgba(189, 186, 186, 1);
}
.statement.row h6 {
  margin: 0 10px;
  color: rgba(0, 167, 157, 1);
  font-size: 16px;
  font-weight: 700;
}
.formtext p {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}
.row.withDrawl.claimForm.NomineeForm label.ant-checkbox-wrapper {
  padding-bottom: 10px;
  padding-top: 25px;
}
.row.statement.uploader p {
  font-weight: 400;
  color: #000;
}
.row.withDrawl.claimForm.NomineeForm label.ant-checkbox-wrapper {
  padding-bottom: 10px;
  padding-top: 10px !important;
}
.withDrawl.claimForm.NomineeForm.row textarea {
  margin-top: 20px;
}
.container.productmenu.homemenu .swiper-pagination-bullet {
  background: #0e76bc !important;
}
.supliments h2 {
  text-align: center !important;
}
.riders .suplimentsicon .supliment .mySuppliment img {
  width: 35% !important;
}
.riders .suplimentsicon .supliment .row.mySuppliment.DIFFERENTIATORS img {
  width: 150px !important;
  margin-bottom: 13px !important;
}
.mySuppliment {
  text-align: center;
}
.tabss .col-sm-12.heads.text .riders .suplimentsicon .supliment .mySuppliment h3 {
  text-align: center !important;
}
.row.mySuppliment {
  padding: 50px;
}
.shares.riders {
  margin-bottom: 0 !important;
}
.col-sm-12.heads.text.mymaxheight.po {
  padding-top: 0;
}
.col-sm-12.formstyle.rightform {
  padding-bottom: 20px;
}
.vidostak h4 {
  color: #fff;
  background-color: #0C551D;
  text-align: center;
  padding: 15px;
}
.col-sm-4.vidostak {
  padding-top: 30px;
  padding-bottom: 30px;
}
.writeus h3 {
  color: #000 !important;
  text-align: left !important;
  margin-bottom: 0 !important;
  padding-top: 30px !important;
  padding-bottom: 0 !important;
}
.sub.productetxt.uniqueprods h3 {
  text-transform: uppercase;
}
.col-sm-3.write p, .col-sm-9.address p {
  color: #000 !important;
}
.col-sm-12.mediaCenterHeading h2 {
  font-weight: 600;
  padding: 100px 0;
  margin: 0;
}
.nnewwBAnnner{
  padding-bottom: 50px;
}
.row.prodbox.grooups .col-sm-4 {
  height: 320px !important;
}
.row.mynewboxes .col-sm-6 {
  background: #1B75BB;
  padding: 25px;
  border-radius: 50px 20px;
  margin: 20px;
  width: 45%;
  height: 250px;
}
.tabss img.indivualBrochure {
  height: 600px;
  object-fit: cover;
}
.row.mynewboxes p {
  width: 100%;
  color: #fff;
  text-align: center;
}
.tabss .col-sm-12.heads.text .row.mynewboxes h3 {
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 38px !important;
  margin-bottom: 0 !important;
}
.row.mynewboxes h4 {
  width: 100%;
  color: #fff;
  text-align: center;
}
.row.mynewboxes {
  width: 85%;
  margin: auto;
  margin-bottom: 100px;
}
.row.addsa.myadongroup .col-sm-3 {
  background: #0C551D;
  margin: 10px;
  height: 200px;
  border-radius: 15px;
  width: 20%;
  padding: 30px 10px;
  transition: all 300ms ease-in-out;
}
.row.addsa.myadongroup {
  justify-content: center;
}
.row.addsa.myadongroup .col-sm-3:hover {
  cursor: pointer;
  background-color: #0e76bc;
  transition: all 300ms ease-in-out;
}
.navbar-expand-lg .navbar-nav {
  flex-direction: row;
  align-items: center;
}
a.buttoncalim {
  background: #0C551D;
  padding: 12px 5px;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  color: #fff;
}
.shariahcom p{
color: #000 !important;
font-weight: 400 !important;
}
a.buttoncalim:hover {
  background: #1B75BB;
  transition: all 300ms ease-in-out;
}
.sub.shariahtabs.carerjobs .nav-link.active {
  background: #0fb583 !important;
  color: #fff !important;
}
.sub.shariahtabs.carerjobs.asaaaaa .nav-link.active {
  color: #fff !important;
}
.heads .sub.shariahtabs.carerjobs li.nav-item button {
  border: 1px solid #0fb583;
  border-radius: 10px !important;
  color: #0fb583 !important;
  padding: 20px;
}
.col-sm-4.fullWIdth {
  width: 100% !important;
}
.sub.shariahtabs.carerjobs .row.tabs.vacancy .col-sm-4.fullWIdth p {
  text-align: center;
  padding: 0 !important;
  font-size: 30px;
  font-weight: 500;
  width: 100% !important;
}
.sub.shariahtabs.carerjobs .row.tabs.vacancy {
  margin-top: 0 !important;
  background: transparent !important;
  padding: 0 0 20px !important;
}
.careerSwiper .swiper-button-next {
  top: 90% !important;
}
.careerSwiper .swiper-button-prev {
  top: 90% !important;
  right: 4% !important;
}
.col-sm-6.buttonscta.bttnsss {
  width: 15%;
}
.heads .sub.shariahtabs.carerjobs li.nav-item {
  background-color: transparent !important;
  border: 1px solid #0fb583;
  padding-bottom: 0 !important;
  color: #0fb583 !important;
  border: unset;
  margin-right: 10px;
}
.calculateForrrm .col-sm-4.steping {
  width: 40% !important;
}
.calculateForrrm .col-sm-8.stepforms.step.steps {
  width: 60% !important;
}
ul.mb-3.nav.nav-tabs.nav-fill {
  border: unset;
}
.carerjobs .nav-link {
  border-radius: 10px;
  background: #0fb583;
}
.carerjobs .nav-link h4 {
  color: #fff;
  font-size: 18px !important;
  margin: 0px auto auto;
  padding: 10px;
  text-align: center;
  transition: all .8s ease-in-out;
  width: 60%;
}
a.nav-link {
  padding: 10px 10px !important;
}
.sub.shariahtabs.carerjobs .nav-link.active h4 {
  background: #0fb583;
  border-radius: 0;
  color: #fff;
  font-size: 28px !important;
  margin: 0 auto auto;
  padding: 120px 10px !important;
  text-align: center;
  transition: all .8s ease-in-out;
  width: 100%;
}
.sub.shariahtabs.carerjobs .row.tabs {
  background: #5D5D5D !important;
}
.sub.shariahtabs.carerjobs .tab-content h3 {
  color: #000;
  text-align: left;
}
.header a.nav-link a {
  color: #fff;
  font-weight: 500;
  font-size: 15px !important;
}
.container.tabss.motor.bgwhite a.nav-link {
  flex-direction: column;
}
button.backbox p {
  text-align: justify;
}
.col-sm-6.explore p {
  text-align: justify;
}
.nav-item.dropdown a {
  font-size: 15px;
}
.col-sm-12.heads.text.mymaxheight.cta {
  padding-top: 0;
}
th.ant-table-cell {
  font-size: larger;
  text-transform: uppercase;
}
.col-sm-12.eligNumber div h1 {
  font-size: 64px;
  margin: 0 !important;
  /* padding: 28px 0; */
}
.row.mySuppliment {
  padding: 50px 0;
  width: 100% !important;
}
.col-sm-12.eligNumber div {
  background: #0889e3;
  border-radius: 50%;
  margin: 0 auto 15px;
  text-align: center;
  width: 135px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disableInput .ant-input-disabled {
  background: rgba(28, 117, 188, 0.16) !important;
  color: #000 !important;
  padding: 20px 10px;
  font-size: 18px;
  margin-top: 10px !important;
}
.proposalForm .withdrawlLine {
  padding: 0 20px;
}
.proposalForm .withdrawlLine h5 {
  color: rgba(0, 167, 157, 1);
  font-weight: 600;
  padding-left: 26px;
}
.btnSection1 {
  text-align: end;
  margin-top: 50px;
}
.NomineeForm .col-sm-8 img {
  position: absolute;
  right: 4%;
  top: 52%;
  cursor: pointer;
}
.lineSec {
  width: 98% !important;
  margin: 37px auto !important;
  border: 1px solid rgb(204, 204, 204);
  box-shadow: none !important;
}
.otpModal1 input {
  width: 4rem;
  margin: 0 5px;
  height: 3rem;
  font-size: 20px;
}
.otpModal1 {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  background-color: rgba(15, 181, 131, 1);
  width: 100%;
  border-radius: 15px;
}
.lineSec p {
  font-size: 17px;
  color: rgba(189, 186, 186, 1);
}
.activeBtn {
  width: 48%;
  padding: 17px;
  border: none;
  border-radius: 10px;
  background: #0c551d;
  color: #fff;
}
.greyBtn {
  background-color: rgba(153, 153, 153, 1) !important;
}
.btnSection1 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.BeneficiaryTable table td {
  padding-top: 20px;
  padding-bottom: 20px;
}
.companyProfile .col-sm-6 {
  text-align: left;
  margin: 20px 0;
}
.companyProfile .col-sm-6 h2 {
  color: #0e76bc;
  font-weight: 600;
}
.companyProfile .col-sm-6 p {
  padding-left: 2px;
}
.companyProfile .accordion-body {
  padding: 0 50px;
}
.companyProfile .accordion-body h5 {
  color: #0889e3;
  font-size: 26px;
  font-weight: 700;
}
.pastPriceForm .ant-select .ant-select-arrow {
  margin-top: -8px;
}
.pastPriceForm .ant-picker .ant-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-inline-start: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
  margin-bottom: 10px;
}
.esgSidebar {
  background: #0c551d !important;
  height: 40px;
  padding-left: 0px !important;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px !important;
}
.esgSidebar a {
  text-decoration: none;
  color: #ffffff;
  font-size: 16px !important;
  font-weight: 900;
  cursor: pointer;
}
.col-sm-4.illustrationImage img {
  width: 100%;
  height: auto;
}
.savingssec img {
  width: 100%;
  margin-bottom: 20px;
  height: 500px !important;
  object-fit: cover !important;
}
.container.policyPage.companyProfile .row.compDetaaailsssssssss {
  align-items: flex-start !important;
}
.skiptranslate iframe {
  display: none;
}
.gogleTransate {
  padding: 10px;
}
.skiptranslate.goog-te-gadget {
  color: transparent;
}
.skiptranslate.goog-te-gadget select {
  color: black;
}
a.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none;
}
.container.tabss.motor.newShariah {
  padding-top: 0;
}
.container.tabss.motor.newShariah .col-sm-12.heads.text h3{
  padding-bottom: 0;
}
.googletranslatee {
  position: fixed;
  top: 100px;
  z-index: 999999999;
  right: 5px;
  opacity: 0;
  animation: fadeIn 0s ease-in-out 4500ms forwards;
}
.googletranslatee select {
  min-width: 120px;
}
body {
  top: 0px !important;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slidesbyvcan .swiper-button-prev {
  top: 82% !important;
  right: unset !important;
  left: 10px !important;
}
.slidesbyvcan .swiper-button-next {
  top: 82% !important;
  right: 10px ! IMPORTANT;
}

a .row.ArticlesAndBlogs .col-sm-7 h4{
  color: #000;
  font-size: 16px;
}